/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Main colour palette
$white            : #FFFFFF;
$black            : #000000;
$blue             : #0065ff;
$blue-alt         : darken($blue, 10) ;
$lightgray        : #efefef;
$bordergray       : #BCBCBC;
$gray             : #888888;
$red              : #FF0000;
$lightyellow      : #ffffe0;

// Assign colours to variable roles
$body-color       : $white;
$base-color       : $black;
$action           : $gray;
$action-response  : $blue;
$action-important : $red;



/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: (
  xs  : 320px,
  sm  : 768px,
  md  : 1024px,
  lg  : 1280px,
  xl  : 1440px,
  xxl : 1920px
);

$grid-gutter-width: 30px !default;



/*------------------------------------*\
  #FONTS
\*------------------------------------*/

%font-body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

%font-body-italic {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-style: italic;
}

%font-body-bold {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-weight: 700;
}

%font-body-bold-italic {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-style: italic;
  font-weight: 700;
}

%font-var {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}



/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/

$font-base         : 1rem;
$line-height       : 1.5;
$line-height-var   : 1.3;

$font-size-xs      : $font-base * 0.666; // 11
$font-size-sm      : $font-base * 0.813; // 13
$font-size-md      : $font-base * 1.000; // 16
$font-size-lg      : $font-base * 1.125; // 18
$font-size-xl      : $font-base * 2.000; // 32
$font-size-xxl     : $font-base * 2.5;   // 48

html {
  font-size: 16px;
  @include media("<sm") {
    font-size: 14px;
  }
}

%font-size-xs {
  font-size: $font-size-xs;
  line-height: $line-height;
  letter-spacing:0.06em;
}
%font-size-sm {
  font-size: $font-size-sm;
  line-height: $line-height;
}
%font-size-md {
  font-size: $font-size-md;
  line-height: $line-height;
}
%font-size-lg {
  font-size: $font-size-lg;
  line-height: $line-height-var;
}
%font-size-xl {
  font-size: $font-size-xl;
  line-height: $line-height-var;
}
%font-size-xxl {
  font-size: $font-size-xxl;
  line-height: $line-height-var;
}



/*------------------------------------*\
  #SPACING
\*------------------------------------*/

$spacing-base     : 1rem;

$spacing-xxxl     : $spacing-base * 6.667; //
$spacing-xxl      : $spacing-base * 4.444; //
$spacing-xl       : $spacing-base * 3.333; //
$spacing-lg       : $spacing-base * 2.222; //
$spacing-md       : $spacing-base * 1.667; //
$spacing-sm       : $spacing-base * 1.111; //
$spacing-xs       : $spacing-base * 0.556; //
$spacing-xxs      : $spacing-base * 0.357; // 5



/*------------------------------------*\
  #BORDERS WIDTH
\*------------------------------------*/

$border-width-sm         : 1px;
$border-width-md         : $border-width-sm*3;
