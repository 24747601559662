@charset "UTF-8";
/*------------------------------------*\
  #MODULES
\*------------------------------------*/
/**
 * simplemde v1.11.2
 * Copyright Next Step Webs, Inc.
 * @link https://github.com/NextStepWebs/simplemde-markdown-editor
 * @license MIT
 */
/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror {
  color: #000; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-lines {
  padding: 4px 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror pre {
  padding: 0 4px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter-filler, .CodeMirror-scrollbar-filler {
  background-color: #fff; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-guttermarker {
  color: #000; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-guttermarker-subtle {
  color: #999; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-cursor {
  border-left: 1px solid #000;
  border-right: none;
  width: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7; }

@-moz-keyframes blink {
  50% {
    background-color: transparent; } }

@-webkit-keyframes blink {
  50% {
    background-color: transparent; } }

@keyframes blink {
  50% {
    background-color: transparent; } }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-tab {
  display: inline-block;
  text-decoration: inherit; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-header {
  color: #00f; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-quote {
  color: #090; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-negative {
  color: #d44; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-positive {
  color: #292; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-header, .cm-strong {
  font-weight: 700; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-em {
  font-style: italic; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-link {
  text-decoration: underline; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-strikethrough {
  text-decoration: line-through; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-keyword {
  color: #708; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-atom {
  color: #219; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-number {
  color: #164; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-def {
  color: #00f; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-variable-2 {
  color: #05a; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-variable-3 {
  color: #085; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-comment {
  color: #a50; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-string {
  color: #a11; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-string-2 {
  color: #f50; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-builtin {
  color: #30a; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-bracket {
  color: #997; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-tag {
  color: #170; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-attribute {
  color: #00c; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-hr {
  color: #999; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-s-default .cm-link {
  color: #00c; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-invalidchar, .cm-s-default .cm-error {
  color: red; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-composing {
  border-bottom: 2px solid; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3); }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-activeline-background {
  background: #e8f2ff; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: #fff; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-scroll {
  overflow: scroll !important;
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: 0;
  position: relative; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter-filler, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-vscrollbar {
  position: absolute;
  z-index: 6;
  display: none; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: 0 0 !important;
  border: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-lines {
  cursor: text;
  min-height: 1px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror pre {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: 0 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-code {
  outline: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber, .CodeMirror-scroll, .CodeMirror-sizer {
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-cursor {
  position: absolute; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-measure pre {
  position: static; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-focused div.CodeMirror-cursors, div.CodeMirror-dragcursors {
  visibility: visible; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-selected {
  background: #d9d9d9; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-focused .CodeMirror-selected, .CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-crosshair {
  cursor: crosshair; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4); }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-force-border {
  padding-right: .1px; }

@media print {
  /* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden; } }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.cm-tab-wrap-hack:after {
  content: ''; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
span.CodeMirror-selectedtext {
  background: 0 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror {
  height: auto;
  min-height: 300px;
  border: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  font: inherit;
  z-index: 1; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-scroll {
  min-height: 300px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-fullscreen {
  background: #fff;
  position: fixed !important;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror-sided {
  width: 50% !important; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar {
  position: relative;
  opacity: .6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0 10px;
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar:after, .editor-toolbar:before {
  display: block;
  content: ' ';
  height: 1px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar:before {
  margin-bottom: 8px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar:after {
  margin-top: 8px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar:hover, .editor-wrapper input.title:focus, .editor-wrapper input.title:hover {
  opacity: .8; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar.fullscreen {
  width: 100%;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: #fff;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 9; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar.fullscreen::before {
  width: 20px;
  height: 50px;
  background: -moz-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, white), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, white 0, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, white 0, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar.fullscreen::after {
  width: 20px;
  height: 50px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), color-stop(100%, white));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0, white 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, white 100%);
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a {
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  color: #2c3e50 !important;
  width: 30px;
  height: 30px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.active, .editor-toolbar a:hover {
  background: #fcfcfc;
  border-color: #95a5a6; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a:before {
  line-height: 30px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar i.separator {
  display: inline-block;
  width: 0;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #fff;
  color: transparent;
  text-indent: -10px;
  margin: 0 6px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.fa-header-x:after {
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  font-size: 65%;
  vertical-align: text-bottom;
  position: relative;
  top: 2px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.fa-header-1:after {
  content: "1"; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.fa-header-2:after {
  content: "2"; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.fa-header-3:after {
  content: "3"; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.fa-header-bigger:after {
  content: "▲"; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar a.fa-header-smaller:after {
  content: "▼"; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  pointer-events: none;
  background: #fff;
  border-color: transparent;
  text-shadow: inherit; }

@media only screen and (max-width: 700px) {
  /* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
  .editor-toolbar a.no-mobile {
    display: none; } }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-statusbar {
  padding: 8px 10px;
  font-size: 12px;
  color: #959694;
  text-align: right; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-statusbar span {
  display: inline-block;
  min-width: 4em;
  margin-left: 1em; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview, .editor-preview-side {
  padding: 10px;
  background: #fafafa;
  overflow: auto;
  display: none;
  box-sizing: border-box; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-statusbar .lines:before {
  content: 'lines: '; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-statusbar .words:before {
  content: 'words: '; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-statusbar .characters:before {
  content: 'characters: '; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 7; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview-side {
  position: fixed;
  bottom: 0;
  width: 50%;
  top: 50px;
  right: 0;
  z-index: 9;
  border: 1px solid #ddd; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview-active, .editor-preview-active-side {
  display: block; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview-side > p, .editor-preview > p {
  margin-top: 0; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview pre, .editor-preview-side pre {
  background: #eee;
  margin-bottom: 10px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.editor-preview table td, .editor-preview table th, .editor-preview-side table td, .editor-preview-side table th {
  border: 1px solid #ddd;
  padding: 5px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-tag {
  color: #63a35c; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-attribute {
  color: #795da3; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-string {
  color: #183691; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-selected {
  background: #d9d9d9; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-1 {
  font-size: 200%;
  line-height: 200%; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-2 {
  font-size: 160%;
  line-height: 160%; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-3 {
  font-size: 125%;
  line-height: 125%; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-header-4 {
  font-size: 110%;
  line-height: 110%; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-comment {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-link {
  color: #7f8c8d; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-url {
  color: #aab2b3; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-code .cm-strikethrough {
  text-decoration: line-through; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .CodeMirror-placeholder {
  opacity: .5; }

/* line 7, resources/assets/admin/css/vendors/02-simplemde.scss */
.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
  background: rgba(255, 0, 0, 0.15); }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-search--inline {
  float: left; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-results {
  display: block; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-results__option[aria-selected] {
  cursor: pointer; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--open .select2-dropdown {
  left: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-search--dropdown {
  display: block;
  padding: 4px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-search--dropdown.select2-search--hide {
  display: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-dropdown--below {
  border-top: none; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

/* line 1, resources/assets/admin/css/vendors/03-select2.min.scss */
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.44
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
/* line 6, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget {
  list-style: none; }

/* line 9, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu {
  margin: 2px 0;
  padding: 4px;
  width: 19em; }

@media (min-width: 768px) {
  /* line 15, resources/assets/admin/css/vendors/04-datepicker.scss */
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 992px) {
  /* line 20, resources/assets/admin/css/vendors/04-datepicker.scss */
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 1200px) {
  /* line 25, resources/assets/admin/css/vendors/04-datepicker.scss */
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

/* line 29, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute; }

/* line 35, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px; }

/* line 43, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px; }

/* line 50, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px; }

/* line 58, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px; }

/* line 65, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px; }

/* line 69, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px; }

/* line 73, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0; }

/* line 76, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0; }

/* line 79, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none; }

/* line 82, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0; }

/* line 90, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px; }

/* line 93, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours"; }

/* line 104, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes"; }

/* line 115, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours"; }

/* line 126, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes"; }

/* line 137, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours"; }

/* line 148, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes"; }

/* line 159, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM"; }

/* line 170, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker"; }

/* line 181, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today"; }

/* line 192, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center; }

/* line 195, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens"; }

/* line 206, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit; }

/* line 213, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%; }

/* line 218, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0; }

/* line 222, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px; }

/* line 227, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px; }

/* line 232, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px; }

/* line 235, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

/* line 241, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month"; }

/* line 252, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month"; }

/* line 263, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer; }

/* line 266, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee; }

/* line 269, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px; }

/* line 274, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777; }

/* line 280, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px; }

/* line 285, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer; }

/* line 292, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777; }

/* line 296, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.today {
  position: relative; }

/* line 299, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

/* line 310, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

/* line 316, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff; }

/* line 319, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

/* line 325, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px; }

/* line 334, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee; }

/* line 337, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

/* line 342, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span.old {
  color: #777777; }

/* line 345, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

/* line 351, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px; }

/* line 355, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget.wider {
  width: 21em; }

/* line 358, resources/assets/admin/css/vendors/04-datepicker.scss */
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important; }

/* line 361, resources/assets/admin/css/vendors/04-datepicker.scss */
.input-group.date .input-group-addon {
  cursor: pointer; }

/* line 364, resources/assets/admin/css/vendors/04-datepicker.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*!
 * Bootstrap Colorpicker v2.5.1
 * https://itsjavi.com/bootstrap-colorpicker/
 *
 * Originally written by (c) 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0.txt
 *
 */
/* line 10, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-saturation {
  width: 100px;
  height: 100px;
  background-image: url("../../../images/admin/bootstrap-colorpicker/saturation.png");
  cursor: crosshair;
  float: left; }

/* line 17, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-saturation i {
  display: block;
  height: 5px;
  width: 5px;
  border: 1px solid #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  margin: -4px 0 0 -4px; }

/* line 30, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-saturation i b {
  display: block;
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

/* line 39, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-hue,
.colorpicker-alpha {
  width: 15px;
  height: 100px;
  float: left;
  cursor: row-resize;
  margin-left: 4px;
  margin-bottom: 4px; }

/* line 48, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-hue i,
.colorpicker-alpha i {
  display: block;
  height: 1px;
  background: #000;
  border-top: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -1px; }

/* line 60, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-hue {
  background-image: url("../../../images/admin/bootstrap-colorpicker/hue.png"); }

/* line 63, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-alpha {
  background-image: url("../../../images/admin/bootstrap-colorpicker/alpha.png");
  display: none; }

/* line 67, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-saturation,
.colorpicker-hue,
.colorpicker-alpha {
  background-size: contain; }

/* line 72, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker {
  padding: 4px;
  min-width: 130px;
  margin-top: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 2500; }

/* line 81, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker:before,
.colorpicker:after {
  display: table;
  content: "";
  line-height: 0; }

/* line 87, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker:after {
  clear: both; }

/* line 90, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px; }

/* line 101, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 7px; }

/* line 111, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker div {
  position: relative; }

/* line 114, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-with-alpha {
  min-width: 140px; }

/* line 117, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
  display: block; }

/* line 120, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-color {
  height: 10px;
  margin-top: 5px;
  clear: both;
  background-image: url("../../../images/admin/bootstrap-colorpicker/alpha.png");
  background-position: 0 100%; }

/* line 127, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-color div {
  height: 10px; }

/* line 130, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-selectors {
  display: none;
  height: 10px;
  margin-top: 5px;
  clear: both; }

/* line 136, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-selectors i {
  cursor: pointer;
  float: left;
  height: 10px;
  width: 10px; }

/* line 142, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-selectors i + i {
  margin-left: 3px; }

/* line 145, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-element .input-group-addon i,
.colorpicker-element .add-on i {
  display: inline-block;
  cursor: pointer;
  height: 16px;
  vertical-align: text-top;
  width: 16px; }

/* line 153, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-inline {
  position: relative;
  display: inline-block;
  float: none;
  z-index: auto; }

/* line 159, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal {
  width: 110px;
  min-width: 110px;
  height: auto; }

/* line 164, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-saturation {
  margin-bottom: 4px; }

/* line 167, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-color {
  width: 100px; }

/* line 170, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-hue,
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  width: 100px;
  height: 15px;
  float: left;
  cursor: col-resize;
  margin-left: 0px;
  margin-bottom: 4px; }

/* line 179, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-hue i,
.colorpicker.colorpicker-horizontal .colorpicker-alpha i {
  display: block;
  height: 15px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  border: none;
  margin-top: 0px; }

/* line 191, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-hue {
  background-image: url("../../../images/admin/bootstrap-colorpicker/hue-horizontal.png"); }

/* line 194, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  background-image: url("../../../images/admin/bootstrap-colorpicker/alpha-horizontal.png"); }

/* line 197, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-right:before {
  left: auto;
  right: 6px; }

/* line 201, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-right:after {
  left: auto;
  right: 7px; }

/* line 205, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-no-arrow:before {
  border-right: 0;
  border-left: 0; }

/* line 209, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-no-arrow:after {
  border-right: 0;
  border-left: 0; }

/* line 213, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-visible,
.colorpicker-alpha.colorpicker-visible,
.colorpicker-saturation.colorpicker-visible,
.colorpicker-hue.colorpicker-visible,
.colorpicker-selectors.colorpicker-visible {
  display: block; }

/* line 220, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker.colorpicker-hidden,
.colorpicker-alpha.colorpicker-hidden,
.colorpicker-saturation.colorpicker-hidden,
.colorpicker-hue.colorpicker-hidden,
.colorpicker-selectors.colorpicker-hidden {
  display: none; }

/* line 227, resources/assets/admin/css/vendors/05-colorpicker.scss */
.colorpicker-inline.colorpicker-visible {
  display: inline-block; }

/*# sourceMappingURL=bootstrap-colorpicker.css.map */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_core.scss */
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
/* line 5, resources/assets/admin/css/vendors/99-fontawesome/_larger.scss */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

/* line 10, resources/assets/admin/css/vendors/99-fontawesome/_larger.scss */
.fa-2x {
  font-size: 2em; }

/* line 11, resources/assets/admin/css/vendors/99-fontawesome/_larger.scss */
.fa-3x {
  font-size: 3em; }

/* line 12, resources/assets/admin/css/vendors/99-fontawesome/_larger.scss */
.fa-4x {
  font-size: 4em; }

/* line 13, resources/assets/admin/css/vendors/99-fontawesome/_larger.scss */
.fa-5x {
  font-size: 5em; }

/* line 3, resources/assets/admin/css/vendors/99-fontawesome/_fixed-width.scss */
.fa-fw {
  width: 1.28571em;
  text-align: center; }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_list.scss */
.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none; }
  /* line 8, resources/assets/admin/css/vendors/99-fontawesome/_list.scss */
  .fa-ul > li {
    position: relative; }

/* line 10, resources/assets/admin/css/vendors/99-fontawesome/_list.scss */
.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center; }
  /* line 16, resources/assets/admin/css/vendors/99-fontawesome/_list.scss */
  .fa-li.fa-lg {
    left: -1.85714em; }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em; }

/* line 10, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa-pull-left {
  float: left; }

/* line 11, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa-pull-right {
  float: right; }

/* line 14, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa.fa-pull-left {
  margin-right: .3em; }

/* line 15, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa.fa-pull-right {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
/* line 19, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.pull-right {
  float: right; }

/* line 20, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.pull-left {
  float: left; }

/* line 23, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa.pull-left {
  margin-right: .3em; }

/* line 24, resources/assets/admin/css/vendors/99-fontawesome/_bordered-pulled.scss */
.fa.pull-right {
  margin-left: .3em; }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_animated.scss */
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

/* line 9, resources/assets/admin/css/vendors/99-fontawesome/_animated.scss */
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_rotated-flipped.scss */
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

/* line 5, resources/assets/admin/css/vendors/99-fontawesome/_rotated-flipped.scss */
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

/* line 6, resources/assets/admin/css/vendors/99-fontawesome/_rotated-flipped.scss */
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

/* line 8, resources/assets/admin/css/vendors/99-fontawesome/_rotated-flipped.scss */
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

/* line 9, resources/assets/admin/css/vendors/99-fontawesome/_rotated-flipped.scss */
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

/* line 14, resources/assets/admin/css/vendors/99-fontawesome/_rotated-flipped.scss */
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_stacked.scss */
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

/* line 12, resources/assets/admin/css/vendors/99-fontawesome/_stacked.scss */
.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

/* line 18, resources/assets/admin/css/vendors/99-fontawesome/_stacked.scss */
.fa-stack-1x {
  line-height: inherit; }

/* line 19, resources/assets/admin/css/vendors/99-fontawesome/_stacked.scss */
.fa-stack-2x {
  font-size: 2em; }

/* line 20, resources/assets/admin/css/vendors/99-fontawesome/_stacked.scss */
.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-glass:before {
  content: ""; }

/* line 5, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-music:before {
  content: ""; }

/* line 6, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-search:before {
  content: ""; }

/* line 7, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-envelope-o:before {
  content: ""; }

/* line 8, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-heart:before {
  content: ""; }

/* line 9, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-star:before {
  content: ""; }

/* line 10, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-star-o:before {
  content: ""; }

/* line 11, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user:before {
  content: ""; }

/* line 12, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-film:before {
  content: ""; }

/* line 13, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-th-large:before {
  content: ""; }

/* line 14, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-th:before {
  content: ""; }

/* line 15, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-th-list:before {
  content: ""; }

/* line 16, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-check:before {
  content: ""; }

/* line 17, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

/* line 20, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-search-plus:before {
  content: ""; }

/* line 21, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-search-minus:before {
  content: ""; }

/* line 22, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-power-off:before {
  content: ""; }

/* line 23, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-signal:before {
  content: ""; }

/* line 24, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gear:before,
.fa-cog:before {
  content: ""; }

/* line 26, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-trash-o:before {
  content: ""; }

/* line 27, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-home:before {
  content: ""; }

/* line 28, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-o:before {
  content: ""; }

/* line 29, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-clock-o:before {
  content: ""; }

/* line 30, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-road:before {
  content: ""; }

/* line 31, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-download:before {
  content: ""; }

/* line 32, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-down:before {
  content: ""; }

/* line 33, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-up:before {
  content: ""; }

/* line 34, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-inbox:before {
  content: ""; }

/* line 35, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-play-circle-o:before {
  content: ""; }

/* line 36, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

/* line 38, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-refresh:before {
  content: ""; }

/* line 39, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-list-alt:before {
  content: ""; }

/* line 40, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-lock:before {
  content: ""; }

/* line 41, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-flag:before {
  content: ""; }

/* line 42, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-headphones:before {
  content: ""; }

/* line 43, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-volume-off:before {
  content: ""; }

/* line 44, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-volume-down:before {
  content: ""; }

/* line 45, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-volume-up:before {
  content: ""; }

/* line 46, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-qrcode:before {
  content: ""; }

/* line 47, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-barcode:before {
  content: ""; }

/* line 48, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tag:before {
  content: ""; }

/* line 49, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tags:before {
  content: ""; }

/* line 50, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-book:before {
  content: ""; }

/* line 51, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bookmark:before {
  content: ""; }

/* line 52, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-print:before {
  content: ""; }

/* line 53, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-camera:before {
  content: ""; }

/* line 54, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-font:before {
  content: ""; }

/* line 55, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bold:before {
  content: ""; }

/* line 56, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-italic:before {
  content: ""; }

/* line 57, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-text-height:before {
  content: ""; }

/* line 58, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-text-width:before {
  content: ""; }

/* line 59, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-align-left:before {
  content: ""; }

/* line 60, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-align-center:before {
  content: ""; }

/* line 61, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-align-right:before {
  content: ""; }

/* line 62, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-align-justify:before {
  content: ""; }

/* line 63, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-list:before {
  content: ""; }

/* line 64, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

/* line 66, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-indent:before {
  content: ""; }

/* line 67, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-video-camera:before {
  content: ""; }

/* line 68, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

/* line 71, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pencil:before {
  content: ""; }

/* line 72, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-map-marker:before {
  content: ""; }

/* line 73, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-adjust:before {
  content: ""; }

/* line 74, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tint:before {
  content: ""; }

/* line 75, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

/* line 77, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-share-square-o:before {
  content: ""; }

/* line 78, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-check-square-o:before {
  content: ""; }

/* line 79, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrows:before {
  content: ""; }

/* line 80, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-step-backward:before {
  content: ""; }

/* line 81, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fast-backward:before {
  content: ""; }

/* line 82, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-backward:before {
  content: ""; }

/* line 83, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-play:before {
  content: ""; }

/* line 84, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pause:before {
  content: ""; }

/* line 85, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stop:before {
  content: ""; }

/* line 86, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-forward:before {
  content: ""; }

/* line 87, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fast-forward:before {
  content: ""; }

/* line 88, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-step-forward:before {
  content: ""; }

/* line 89, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-eject:before {
  content: ""; }

/* line 90, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-left:before {
  content: ""; }

/* line 91, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-right:before {
  content: ""; }

/* line 92, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-plus-circle:before {
  content: ""; }

/* line 93, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-minus-circle:before {
  content: ""; }

/* line 94, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-times-circle:before {
  content: ""; }

/* line 95, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-check-circle:before {
  content: ""; }

/* line 96, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-question-circle:before {
  content: ""; }

/* line 97, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-info-circle:before {
  content: ""; }

/* line 98, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-crosshairs:before {
  content: ""; }

/* line 99, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-times-circle-o:before {
  content: ""; }

/* line 100, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-check-circle-o:before {
  content: ""; }

/* line 101, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ban:before {
  content: ""; }

/* line 102, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-left:before {
  content: ""; }

/* line 103, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-right:before {
  content: ""; }

/* line 104, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-up:before {
  content: ""; }

/* line 105, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-down:before {
  content: ""; }

/* line 106, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

/* line 108, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-expand:before {
  content: ""; }

/* line 109, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-compress:before {
  content: ""; }

/* line 110, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-plus:before {
  content: ""; }

/* line 111, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-minus:before {
  content: ""; }

/* line 112, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-asterisk:before {
  content: ""; }

/* line 113, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-exclamation-circle:before {
  content: ""; }

/* line 114, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gift:before {
  content: ""; }

/* line 115, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-leaf:before {
  content: ""; }

/* line 116, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fire:before {
  content: ""; }

/* line 117, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-eye:before {
  content: ""; }

/* line 118, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-eye-slash:before {
  content: ""; }

/* line 119, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

/* line 121, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-plane:before {
  content: ""; }

/* line 122, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calendar:before {
  content: ""; }

/* line 123, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-random:before {
  content: ""; }

/* line 124, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-comment:before {
  content: ""; }

/* line 125, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-magnet:before {
  content: ""; }

/* line 126, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-up:before {
  content: ""; }

/* line 127, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-down:before {
  content: ""; }

/* line 128, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-retweet:before {
  content: ""; }

/* line 129, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shopping-cart:before {
  content: ""; }

/* line 130, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-folder:before {
  content: ""; }

/* line 131, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-folder-open:before {
  content: ""; }

/* line 132, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrows-v:before {
  content: ""; }

/* line 133, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrows-h:before {
  content: ""; }

/* line 134, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

/* line 136, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-twitter-square:before {
  content: ""; }

/* line 137, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-facebook-square:before {
  content: ""; }

/* line 138, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-camera-retro:before {
  content: ""; }

/* line 139, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-key:before {
  content: ""; }

/* line 140, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gears:before,
.fa-cogs:before {
  content: ""; }

/* line 142, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-comments:before {
  content: ""; }

/* line 143, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thumbs-o-up:before {
  content: ""; }

/* line 144, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thumbs-o-down:before {
  content: ""; }

/* line 145, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-star-half:before {
  content: ""; }

/* line 146, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-heart-o:before {
  content: ""; }

/* line 147, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sign-out:before {
  content: ""; }

/* line 148, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-linkedin-square:before {
  content: ""; }

/* line 149, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thumb-tack:before {
  content: ""; }

/* line 150, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-external-link:before {
  content: ""; }

/* line 151, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sign-in:before {
  content: ""; }

/* line 152, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-trophy:before {
  content: ""; }

/* line 153, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-github-square:before {
  content: ""; }

/* line 154, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-upload:before {
  content: ""; }

/* line 155, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-lemon-o:before {
  content: ""; }

/* line 156, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-phone:before {
  content: ""; }

/* line 157, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-square-o:before {
  content: ""; }

/* line 158, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bookmark-o:before {
  content: ""; }

/* line 159, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-phone-square:before {
  content: ""; }

/* line 160, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-twitter:before {
  content: ""; }

/* line 161, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

/* line 163, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-github:before {
  content: ""; }

/* line 164, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-unlock:before {
  content: ""; }

/* line 165, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-credit-card:before {
  content: ""; }

/* line 166, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-feed:before,
.fa-rss:before {
  content: ""; }

/* line 168, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hdd-o:before {
  content: ""; }

/* line 169, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bullhorn:before {
  content: ""; }

/* line 170, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bell:before {
  content: ""; }

/* line 171, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-certificate:before {
  content: ""; }

/* line 172, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-o-right:before {
  content: ""; }

/* line 173, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-o-left:before {
  content: ""; }

/* line 174, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-o-up:before {
  content: ""; }

/* line 175, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-o-down:before {
  content: ""; }

/* line 176, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-left:before {
  content: ""; }

/* line 177, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-right:before {
  content: ""; }

/* line 178, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-up:before {
  content: ""; }

/* line 179, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-down:before {
  content: ""; }

/* line 180, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-globe:before {
  content: ""; }

/* line 181, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wrench:before {
  content: ""; }

/* line 182, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tasks:before {
  content: ""; }

/* line 183, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-filter:before {
  content: ""; }

/* line 184, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-briefcase:before {
  content: ""; }

/* line 185, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrows-alt:before {
  content: ""; }

/* line 186, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-group:before,
.fa-users:before {
  content: ""; }

/* line 188, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chain:before,
.fa-link:before {
  content: ""; }

/* line 190, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cloud:before {
  content: ""; }

/* line 191, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-flask:before {
  content: ""; }

/* line 192, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cut:before,
.fa-scissors:before {
  content: ""; }

/* line 194, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-copy:before,
.fa-files-o:before {
  content: ""; }

/* line 196, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-paperclip:before {
  content: ""; }

/* line 197, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

/* line 199, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-square:before {
  content: ""; }

/* line 200, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

/* line 203, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-list-ul:before {
  content: ""; }

/* line 204, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-list-ol:before {
  content: ""; }

/* line 205, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-strikethrough:before {
  content: ""; }

/* line 206, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-underline:before {
  content: ""; }

/* line 207, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-table:before {
  content: ""; }

/* line 208, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-magic:before {
  content: ""; }

/* line 209, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-truck:before {
  content: ""; }

/* line 210, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pinterest:before {
  content: ""; }

/* line 211, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pinterest-square:before {
  content: ""; }

/* line 212, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-google-plus-square:before {
  content: ""; }

/* line 213, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-google-plus:before {
  content: ""; }

/* line 214, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-money:before {
  content: ""; }

/* line 215, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-caret-down:before {
  content: ""; }

/* line 216, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-caret-up:before {
  content: ""; }

/* line 217, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-caret-left:before {
  content: ""; }

/* line 218, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-caret-right:before {
  content: ""; }

/* line 219, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-columns:before {
  content: ""; }

/* line 220, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

/* line 222, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

/* line 224, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

/* line 226, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-envelope:before {
  content: ""; }

/* line 227, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-linkedin:before {
  content: ""; }

/* line 228, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

/* line 230, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-legal:before,
.fa-gavel:before {
  content: ""; }

/* line 232, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

/* line 234, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-comment-o:before {
  content: ""; }

/* line 235, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-comments-o:before {
  content: ""; }

/* line 236, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-flash:before,
.fa-bolt:before {
  content: ""; }

/* line 238, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sitemap:before {
  content: ""; }

/* line 239, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-umbrella:before {
  content: ""; }

/* line 240, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

/* line 242, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-lightbulb-o:before {
  content: ""; }

/* line 243, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-exchange:before {
  content: ""; }

/* line 244, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cloud-download:before {
  content: ""; }

/* line 245, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cloud-upload:before {
  content: ""; }

/* line 246, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-md:before {
  content: ""; }

/* line 247, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stethoscope:before {
  content: ""; }

/* line 248, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-suitcase:before {
  content: ""; }

/* line 249, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bell-o:before {
  content: ""; }

/* line 250, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-coffee:before {
  content: ""; }

/* line 251, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cutlery:before {
  content: ""; }

/* line 252, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-text-o:before {
  content: ""; }

/* line 253, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-building-o:before {
  content: ""; }

/* line 254, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hospital-o:before {
  content: ""; }

/* line 255, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ambulance:before {
  content: ""; }

/* line 256, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-medkit:before {
  content: ""; }

/* line 257, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fighter-jet:before {
  content: ""; }

/* line 258, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-beer:before {
  content: ""; }

/* line 259, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-h-square:before {
  content: ""; }

/* line 260, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-plus-square:before {
  content: ""; }

/* line 261, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-double-left:before {
  content: ""; }

/* line 262, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-double-right:before {
  content: ""; }

/* line 263, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-double-up:before {
  content: ""; }

/* line 264, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-double-down:before {
  content: ""; }

/* line 265, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-left:before {
  content: ""; }

/* line 266, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-right:before {
  content: ""; }

/* line 267, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-up:before {
  content: ""; }

/* line 268, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angle-down:before {
  content: ""; }

/* line 269, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-desktop:before {
  content: ""; }

/* line 270, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-laptop:before {
  content: ""; }

/* line 271, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tablet:before {
  content: ""; }

/* line 272, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

/* line 274, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-circle-o:before {
  content: ""; }

/* line 275, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-quote-left:before {
  content: ""; }

/* line 276, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-quote-right:before {
  content: ""; }

/* line 277, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-spinner:before {
  content: ""; }

/* line 278, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-circle:before {
  content: ""; }

/* line 279, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

/* line 281, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-github-alt:before {
  content: ""; }

/* line 282, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-folder-o:before {
  content: ""; }

/* line 283, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-folder-open-o:before {
  content: ""; }

/* line 284, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-smile-o:before {
  content: ""; }

/* line 285, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-frown-o:before {
  content: ""; }

/* line 286, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-meh-o:before {
  content: ""; }

/* line 287, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gamepad:before {
  content: ""; }

/* line 288, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-keyboard-o:before {
  content: ""; }

/* line 289, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-flag-o:before {
  content: ""; }

/* line 290, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-flag-checkered:before {
  content: ""; }

/* line 291, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-terminal:before {
  content: ""; }

/* line 292, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-code:before {
  content: ""; }

/* line 293, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

/* line 295, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

/* line 298, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-location-arrow:before {
  content: ""; }

/* line 299, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-crop:before {
  content: ""; }

/* line 300, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-code-fork:before {
  content: ""; }

/* line 301, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

/* line 303, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-question:before {
  content: ""; }

/* line 304, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-info:before {
  content: ""; }

/* line 305, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-exclamation:before {
  content: ""; }

/* line 306, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-superscript:before {
  content: ""; }

/* line 307, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-subscript:before {
  content: ""; }

/* line 308, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-eraser:before {
  content: ""; }

/* line 309, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-puzzle-piece:before {
  content: ""; }

/* line 310, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-microphone:before {
  content: ""; }

/* line 311, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-microphone-slash:before {
  content: ""; }

/* line 312, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shield:before {
  content: ""; }

/* line 313, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calendar-o:before {
  content: ""; }

/* line 314, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fire-extinguisher:before {
  content: ""; }

/* line 315, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-rocket:before {
  content: ""; }

/* line 316, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-maxcdn:before {
  content: ""; }

/* line 317, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-circle-left:before {
  content: ""; }

/* line 318, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-circle-right:before {
  content: ""; }

/* line 319, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-circle-up:before {
  content: ""; }

/* line 320, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chevron-circle-down:before {
  content: ""; }

/* line 321, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-html5:before {
  content: ""; }

/* line 322, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-css3:before {
  content: ""; }

/* line 323, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-anchor:before {
  content: ""; }

/* line 324, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-unlock-alt:before {
  content: ""; }

/* line 325, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bullseye:before {
  content: ""; }

/* line 326, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ellipsis-h:before {
  content: ""; }

/* line 327, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ellipsis-v:before {
  content: ""; }

/* line 328, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-rss-square:before {
  content: ""; }

/* line 329, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-play-circle:before {
  content: ""; }

/* line 330, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ticket:before {
  content: ""; }

/* line 331, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-minus-square:before {
  content: ""; }

/* line 332, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-minus-square-o:before {
  content: ""; }

/* line 333, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-level-up:before {
  content: ""; }

/* line 334, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-level-down:before {
  content: ""; }

/* line 335, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-check-square:before {
  content: ""; }

/* line 336, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pencil-square:before {
  content: ""; }

/* line 337, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-external-link-square:before {
  content: ""; }

/* line 338, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-share-square:before {
  content: ""; }

/* line 339, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-compass:before {
  content: ""; }

/* line 340, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

/* line 342, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

/* line 344, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

/* line 346, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-euro:before,
.fa-eur:before {
  content: ""; }

/* line 348, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gbp:before {
  content: ""; }

/* line 349, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dollar:before,
.fa-usd:before {
  content: ""; }

/* line 351, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-rupee:before,
.fa-inr:before {
  content: ""; }

/* line 353, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

/* line 357, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

/* line 360, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-won:before,
.fa-krw:before {
  content: ""; }

/* line 362, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

/* line 364, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file:before {
  content: ""; }

/* line 365, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-text:before {
  content: ""; }

/* line 366, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-alpha-asc:before {
  content: ""; }

/* line 367, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-alpha-desc:before {
  content: ""; }

/* line 368, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-amount-asc:before {
  content: ""; }

/* line 369, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-amount-desc:before {
  content: ""; }

/* line 370, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-numeric-asc:before {
  content: ""; }

/* line 371, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sort-numeric-desc:before {
  content: ""; }

/* line 372, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thumbs-up:before {
  content: ""; }

/* line 373, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thumbs-down:before {
  content: ""; }

/* line 374, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-youtube-square:before {
  content: ""; }

/* line 375, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-youtube:before {
  content: ""; }

/* line 376, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-xing:before {
  content: ""; }

/* line 377, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-xing-square:before {
  content: ""; }

/* line 378, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-youtube-play:before {
  content: ""; }

/* line 379, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dropbox:before {
  content: ""; }

/* line 380, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stack-overflow:before {
  content: ""; }

/* line 381, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-instagram:before {
  content: ""; }

/* line 382, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-flickr:before {
  content: ""; }

/* line 383, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-adn:before {
  content: ""; }

/* line 384, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bitbucket:before {
  content: ""; }

/* line 385, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bitbucket-square:before {
  content: ""; }

/* line 386, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tumblr:before {
  content: ""; }

/* line 387, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tumblr-square:before {
  content: ""; }

/* line 388, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-long-arrow-down:before {
  content: ""; }

/* line 389, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-long-arrow-up:before {
  content: ""; }

/* line 390, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-long-arrow-left:before {
  content: ""; }

/* line 391, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-long-arrow-right:before {
  content: ""; }

/* line 392, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-apple:before {
  content: ""; }

/* line 393, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-windows:before {
  content: ""; }

/* line 394, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-android:before {
  content: ""; }

/* line 395, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-linux:before {
  content: ""; }

/* line 396, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dribbble:before {
  content: ""; }

/* line 397, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-skype:before {
  content: ""; }

/* line 398, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-foursquare:before {
  content: ""; }

/* line 399, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-trello:before {
  content: ""; }

/* line 400, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-female:before {
  content: ""; }

/* line 401, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-male:before {
  content: ""; }

/* line 402, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

/* line 404, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sun-o:before {
  content: ""; }

/* line 405, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-moon-o:before {
  content: ""; }

/* line 406, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-archive:before {
  content: ""; }

/* line 407, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bug:before {
  content: ""; }

/* line 408, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-vk:before {
  content: ""; }

/* line 409, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-weibo:before {
  content: ""; }

/* line 410, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-renren:before {
  content: ""; }

/* line 411, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pagelines:before {
  content: ""; }

/* line 412, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stack-exchange:before {
  content: ""; }

/* line 413, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-right:before {
  content: ""; }

/* line 414, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-arrow-circle-o-left:before {
  content: ""; }

/* line 415, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

/* line 417, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dot-circle-o:before {
  content: ""; }

/* line 418, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wheelchair:before {
  content: ""; }

/* line 419, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-vimeo-square:before {
  content: ""; }

/* line 420, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

/* line 422, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-plus-square-o:before {
  content: ""; }

/* line 423, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-space-shuttle:before {
  content: ""; }

/* line 424, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-slack:before {
  content: ""; }

/* line 425, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-envelope-square:before {
  content: ""; }

/* line 426, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wordpress:before {
  content: ""; }

/* line 427, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-openid:before {
  content: ""; }

/* line 428, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

/* line 431, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

/* line 433, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-yahoo:before {
  content: ""; }

/* line 434, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-google:before {
  content: ""; }

/* line 435, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-reddit:before {
  content: ""; }

/* line 436, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-reddit-square:before {
  content: ""; }

/* line 437, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stumbleupon-circle:before {
  content: ""; }

/* line 438, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stumbleupon:before {
  content: ""; }

/* line 439, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-delicious:before {
  content: ""; }

/* line 440, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-digg:before {
  content: ""; }

/* line 441, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pied-piper-pp:before {
  content: ""; }

/* line 442, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pied-piper-alt:before {
  content: ""; }

/* line 443, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-drupal:before {
  content: ""; }

/* line 444, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-joomla:before {
  content: ""; }

/* line 445, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-language:before {
  content: ""; }

/* line 446, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fax:before {
  content: ""; }

/* line 447, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-building:before {
  content: ""; }

/* line 448, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-child:before {
  content: ""; }

/* line 449, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-paw:before {
  content: ""; }

/* line 450, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-spoon:before {
  content: ""; }

/* line 451, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cube:before {
  content: ""; }

/* line 452, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cubes:before {
  content: ""; }

/* line 453, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-behance:before {
  content: ""; }

/* line 454, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-behance-square:before {
  content: ""; }

/* line 455, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-steam:before {
  content: ""; }

/* line 456, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-steam-square:before {
  content: ""; }

/* line 457, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-recycle:before {
  content: ""; }

/* line 458, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-automobile:before,
.fa-car:before {
  content: ""; }

/* line 460, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cab:before,
.fa-taxi:before {
  content: ""; }

/* line 462, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tree:before {
  content: ""; }

/* line 463, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-spotify:before {
  content: ""; }

/* line 464, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-deviantart:before {
  content: ""; }

/* line 465, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-soundcloud:before {
  content: ""; }

/* line 466, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-database:before {
  content: ""; }

/* line 467, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-pdf-o:before {
  content: ""; }

/* line 468, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-word-o:before {
  content: ""; }

/* line 469, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-excel-o:before {
  content: ""; }

/* line 470, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-powerpoint-o:before {
  content: ""; }

/* line 471, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

/* line 474, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

/* line 476, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

/* line 478, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

/* line 480, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-file-code-o:before {
  content: ""; }

/* line 481, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-vine:before {
  content: ""; }

/* line 482, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-codepen:before {
  content: ""; }

/* line 483, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-jsfiddle:before {
  content: ""; }

/* line 484, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

/* line 489, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-circle-o-notch:before {
  content: ""; }

/* line 490, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

/* line 493, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ge:before,
.fa-empire:before {
  content: ""; }

/* line 495, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-git-square:before {
  content: ""; }

/* line 496, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-git:before {
  content: ""; }

/* line 497, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

/* line 500, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tencent-weibo:before {
  content: ""; }

/* line 501, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-qq:before {
  content: ""; }

/* line 502, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

/* line 504, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

/* line 506, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

/* line 508, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-history:before {
  content: ""; }

/* line 509, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-circle-thin:before {
  content: ""; }

/* line 510, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-header:before {
  content: ""; }

/* line 511, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-paragraph:before {
  content: ""; }

/* line 512, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sliders:before {
  content: ""; }

/* line 513, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-share-alt:before {
  content: ""; }

/* line 514, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-share-alt-square:before {
  content: ""; }

/* line 515, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bomb:before {
  content: ""; }

/* line 516, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

/* line 518, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tty:before {
  content: ""; }

/* line 519, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-binoculars:before {
  content: ""; }

/* line 520, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-plug:before {
  content: ""; }

/* line 521, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-slideshare:before {
  content: ""; }

/* line 522, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-twitch:before {
  content: ""; }

/* line 523, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-yelp:before {
  content: ""; }

/* line 524, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-newspaper-o:before {
  content: ""; }

/* line 525, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wifi:before {
  content: ""; }

/* line 526, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calculator:before {
  content: ""; }

/* line 527, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-paypal:before {
  content: ""; }

/* line 528, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-google-wallet:before {
  content: ""; }

/* line 529, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-visa:before {
  content: ""; }

/* line 530, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-mastercard:before {
  content: ""; }

/* line 531, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-discover:before {
  content: ""; }

/* line 532, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-amex:before {
  content: ""; }

/* line 533, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-paypal:before {
  content: ""; }

/* line 534, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-stripe:before {
  content: ""; }

/* line 535, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bell-slash:before {
  content: ""; }

/* line 536, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bell-slash-o:before {
  content: ""; }

/* line 537, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-trash:before {
  content: ""; }

/* line 538, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-copyright:before {
  content: ""; }

/* line 539, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-at:before {
  content: ""; }

/* line 540, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-eyedropper:before {
  content: ""; }

/* line 541, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-paint-brush:before {
  content: ""; }

/* line 542, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-birthday-cake:before {
  content: ""; }

/* line 543, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-area-chart:before {
  content: ""; }

/* line 544, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pie-chart:before {
  content: ""; }

/* line 545, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-line-chart:before {
  content: ""; }

/* line 546, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-lastfm:before {
  content: ""; }

/* line 547, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-lastfm-square:before {
  content: ""; }

/* line 548, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-toggle-off:before {
  content: ""; }

/* line 549, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-toggle-on:before {
  content: ""; }

/* line 550, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bicycle:before {
  content: ""; }

/* line 551, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bus:before {
  content: ""; }

/* line 552, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ioxhost:before {
  content: ""; }

/* line 553, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-angellist:before {
  content: ""; }

/* line 554, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc:before {
  content: ""; }

/* line 555, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

/* line 558, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-meanpath:before {
  content: ""; }

/* line 559, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-buysellads:before {
  content: ""; }

/* line 560, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-connectdevelop:before {
  content: ""; }

/* line 561, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-dashcube:before {
  content: ""; }

/* line 562, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-forumbee:before {
  content: ""; }

/* line 563, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-leanpub:before {
  content: ""; }

/* line 564, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sellsy:before {
  content: ""; }

/* line 565, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shirtsinbulk:before {
  content: ""; }

/* line 566, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-simplybuilt:before {
  content: ""; }

/* line 567, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-skyatlas:before {
  content: ""; }

/* line 568, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cart-plus:before {
  content: ""; }

/* line 569, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cart-arrow-down:before {
  content: ""; }

/* line 570, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-diamond:before {
  content: ""; }

/* line 571, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ship:before {
  content: ""; }

/* line 572, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-secret:before {
  content: ""; }

/* line 573, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-motorcycle:before {
  content: ""; }

/* line 574, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-street-view:before {
  content: ""; }

/* line 575, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-heartbeat:before {
  content: ""; }

/* line 576, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-venus:before {
  content: ""; }

/* line 577, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mars:before {
  content: ""; }

/* line 578, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mercury:before {
  content: ""; }

/* line 579, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

/* line 581, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-transgender-alt:before {
  content: ""; }

/* line 582, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-venus-double:before {
  content: ""; }

/* line 583, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mars-double:before {
  content: ""; }

/* line 584, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-venus-mars:before {
  content: ""; }

/* line 585, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mars-stroke:before {
  content: ""; }

/* line 586, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mars-stroke-v:before {
  content: ""; }

/* line 587, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mars-stroke-h:before {
  content: ""; }

/* line 588, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-neuter:before {
  content: ""; }

/* line 589, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-genderless:before {
  content: ""; }

/* line 590, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-facebook-official:before {
  content: ""; }

/* line 591, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pinterest-p:before {
  content: ""; }

/* line 592, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-whatsapp:before {
  content: ""; }

/* line 593, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-server:before {
  content: ""; }

/* line 594, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-plus:before {
  content: ""; }

/* line 595, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-times:before {
  content: ""; }

/* line 596, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hotel:before,
.fa-bed:before {
  content: ""; }

/* line 598, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-viacoin:before {
  content: ""; }

/* line 599, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-train:before {
  content: ""; }

/* line 600, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-subway:before {
  content: ""; }

/* line 601, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-medium:before {
  content: ""; }

/* line 602, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

/* line 604, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-optin-monster:before {
  content: ""; }

/* line 605, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-opencart:before {
  content: ""; }

/* line 606, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-expeditedssl:before {
  content: ""; }

/* line 607, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: ""; }

/* line 610, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

/* line 612, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

/* line 614, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

/* line 616, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

/* line 618, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mouse-pointer:before {
  content: ""; }

/* line 619, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-i-cursor:before {
  content: ""; }

/* line 620, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-object-group:before {
  content: ""; }

/* line 621, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-object-ungroup:before {
  content: ""; }

/* line 622, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sticky-note:before {
  content: ""; }

/* line 623, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-sticky-note-o:before {
  content: ""; }

/* line 624, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-jcb:before {
  content: ""; }

/* line 625, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-cc-diners-club:before {
  content: ""; }

/* line 626, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-clone:before {
  content: ""; }

/* line 627, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-balance-scale:before {
  content: ""; }

/* line 628, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hourglass-o:before {
  content: ""; }

/* line 629, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

/* line 631, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

/* line 633, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

/* line 635, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hourglass:before {
  content: ""; }

/* line 636, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

/* line 638, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

/* line 640, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-scissors-o:before {
  content: ""; }

/* line 641, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-lizard-o:before {
  content: ""; }

/* line 642, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-spock-o:before {
  content: ""; }

/* line 643, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-pointer-o:before {
  content: ""; }

/* line 644, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hand-peace-o:before {
  content: ""; }

/* line 645, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-trademark:before {
  content: ""; }

/* line 646, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-registered:before {
  content: ""; }

/* line 647, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-creative-commons:before {
  content: ""; }

/* line 648, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gg:before {
  content: ""; }

/* line 649, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gg-circle:before {
  content: ""; }

/* line 650, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tripadvisor:before {
  content: ""; }

/* line 651, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-odnoklassniki:before {
  content: ""; }

/* line 652, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-odnoklassniki-square:before {
  content: ""; }

/* line 653, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-get-pocket:before {
  content: ""; }

/* line 654, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wikipedia-w:before {
  content: ""; }

/* line 655, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-safari:before {
  content: ""; }

/* line 656, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-chrome:before {
  content: ""; }

/* line 657, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-firefox:before {
  content: ""; }

/* line 658, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-opera:before {
  content: ""; }

/* line 659, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-internet-explorer:before {
  content: ""; }

/* line 660, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-tv:before,
.fa-television:before {
  content: ""; }

/* line 662, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-contao:before {
  content: ""; }

/* line 663, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-500px:before {
  content: ""; }

/* line 664, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-amazon:before {
  content: ""; }

/* line 665, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calendar-plus-o:before {
  content: ""; }

/* line 666, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calendar-minus-o:before {
  content: ""; }

/* line 667, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calendar-times-o:before {
  content: ""; }

/* line 668, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-calendar-check-o:before {
  content: ""; }

/* line 669, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-industry:before {
  content: ""; }

/* line 670, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-map-pin:before {
  content: ""; }

/* line 671, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-map-signs:before {
  content: ""; }

/* line 672, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-map-o:before {
  content: ""; }

/* line 673, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-map:before {
  content: ""; }

/* line 674, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-commenting:before {
  content: ""; }

/* line 675, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-commenting-o:before {
  content: ""; }

/* line 676, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-houzz:before {
  content: ""; }

/* line 677, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-vimeo:before {
  content: ""; }

/* line 678, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-black-tie:before {
  content: ""; }

/* line 679, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fonticons:before {
  content: ""; }

/* line 680, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-reddit-alien:before {
  content: ""; }

/* line 681, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-edge:before {
  content: ""; }

/* line 682, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-credit-card-alt:before {
  content: ""; }

/* line 683, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-codiepie:before {
  content: ""; }

/* line 684, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-modx:before {
  content: ""; }

/* line 685, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fort-awesome:before {
  content: ""; }

/* line 686, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-usb:before {
  content: ""; }

/* line 687, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-product-hunt:before {
  content: ""; }

/* line 688, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-mixcloud:before {
  content: ""; }

/* line 689, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-scribd:before {
  content: ""; }

/* line 690, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pause-circle:before {
  content: ""; }

/* line 691, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pause-circle-o:before {
  content: ""; }

/* line 692, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stop-circle:before {
  content: ""; }

/* line 693, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-stop-circle-o:before {
  content: ""; }

/* line 694, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shopping-bag:before {
  content: ""; }

/* line 695, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shopping-basket:before {
  content: ""; }

/* line 696, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-hashtag:before {
  content: ""; }

/* line 697, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bluetooth:before {
  content: ""; }

/* line 698, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bluetooth-b:before {
  content: ""; }

/* line 699, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-percent:before {
  content: ""; }

/* line 700, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-gitlab:before {
  content: ""; }

/* line 701, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wpbeginner:before {
  content: ""; }

/* line 702, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wpforms:before {
  content: ""; }

/* line 703, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-envira:before {
  content: ""; }

/* line 704, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-universal-access:before {
  content: ""; }

/* line 705, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wheelchair-alt:before {
  content: ""; }

/* line 706, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-question-circle-o:before {
  content: ""; }

/* line 707, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-blind:before {
  content: ""; }

/* line 708, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-audio-description:before {
  content: ""; }

/* line 709, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-volume-control-phone:before {
  content: ""; }

/* line 710, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-braille:before {
  content: ""; }

/* line 711, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-assistive-listening-systems:before {
  content: ""; }

/* line 712, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

/* line 714, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

/* line 717, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-glide:before {
  content: ""; }

/* line 718, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-glide-g:before {
  content: ""; }

/* line 719, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

/* line 721, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-low-vision:before {
  content: ""; }

/* line 722, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-viadeo:before {
  content: ""; }

/* line 723, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-viadeo-square:before {
  content: ""; }

/* line 724, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-snapchat:before {
  content: ""; }

/* line 725, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-snapchat-ghost:before {
  content: ""; }

/* line 726, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-snapchat-square:before {
  content: ""; }

/* line 727, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-pied-piper:before {
  content: ""; }

/* line 728, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-first-order:before {
  content: ""; }

/* line 729, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-yoast:before {
  content: ""; }

/* line 730, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-themeisle:before {
  content: ""; }

/* line 731, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

/* line 733, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

/* line 735, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-handshake-o:before {
  content: ""; }

/* line 736, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-envelope-open:before {
  content: ""; }

/* line 737, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-envelope-open-o:before {
  content: ""; }

/* line 738, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-linode:before {
  content: ""; }

/* line 739, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-address-book:before {
  content: ""; }

/* line 740, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-address-book-o:before {
  content: ""; }

/* line 741, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-vcard:before,
.fa-address-card:before {
  content: ""; }

/* line 743, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-vcard-o:before,
.fa-address-card-o:before {
  content: ""; }

/* line 745, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-circle:before {
  content: ""; }

/* line 746, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-circle-o:before {
  content: ""; }

/* line 747, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-user-o:before {
  content: ""; }

/* line 748, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-id-badge:before {
  content: ""; }

/* line 749, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-drivers-license:before,
.fa-id-card:before {
  content: ""; }

/* line 751, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: ""; }

/* line 753, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-quora:before {
  content: ""; }

/* line 754, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-free-code-camp:before {
  content: ""; }

/* line 755, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-telegram:before {
  content: ""; }

/* line 756, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: ""; }

/* line 759, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: ""; }

/* line 761, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: ""; }

/* line 763, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: ""; }

/* line 765, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: ""; }

/* line 767, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-shower:before {
  content: ""; }

/* line 768, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: ""; }

/* line 771, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-podcast:before {
  content: ""; }

/* line 772, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-window-maximize:before {
  content: ""; }

/* line 773, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-window-minimize:before {
  content: ""; }

/* line 774, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-window-restore:before {
  content: ""; }

/* line 775, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-times-rectangle:before,
.fa-window-close:before {
  content: ""; }

/* line 777, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: ""; }

/* line 779, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-bandcamp:before {
  content: ""; }

/* line 780, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-grav:before {
  content: ""; }

/* line 781, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-etsy:before {
  content: ""; }

/* line 782, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-imdb:before {
  content: ""; }

/* line 783, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-ravelry:before {
  content: ""; }

/* line 784, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-eercast:before {
  content: ""; }

/* line 785, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-microchip:before {
  content: ""; }

/* line 786, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-snowflake-o:before {
  content: ""; }

/* line 787, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-superpowers:before {
  content: ""; }

/* line 788, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-wpexplorer:before {
  content: ""; }

/* line 789, resources/assets/admin/css/vendors/99-fontawesome/_icons.scss */
.fa-meetup:before {
  content: ""; }

/* line 4, resources/assets/admin/css/vendors/99-fontawesome/_screen-reader.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/* line 51, resources/assets/admin/css/vendors/99-fontawesome/_mixins.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

/* line 4, resources/assets/admin/css/modules/_reset.scss */
*, *:before, *:after {
  box-sizing: border-box; }

/* line 6, resources/assets/admin/css/modules/_reset.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* line 7, resources/assets/admin/css/modules/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 8, resources/assets/admin/css/modules/_reset.scss */
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/* line 9, resources/assets/admin/css/modules/_reset.scss */
q, blockquote {
  quotes: none; }

/* line 10, resources/assets/admin/css/modules/_reset.scss */
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

/* line 11, resources/assets/admin/css/modules/_reset.scss */
a img {
  border: none; }

/* line 12, resources/assets/admin/css/modules/_reset.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

/* line 18, resources/assets/admin/css/modules/_reset.scss */
button, input {
  line-height: normal; }

/* line 19, resources/assets/admin/css/modules/_reset.scss */
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/* line 20, resources/assets/admin/css/modules/_reset.scss */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  *overflow: visible; }

/* line 21, resources/assets/admin/css/modules/_reset.scss */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* line 22, resources/assets/admin/css/modules/_reset.scss */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/*------------------------------------*\
  #COLORS
\*------------------------------------*/
/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*\
  #FONTS
\*------------------------------------*/
/* line 46, resources/assets/admin/css/modules/_variables.scss */
.ts-body, .editor-preview-side dl,
.editor-preview dl, .editor-preview-side ol li,
.editor-preview ol li, .editor-preview-side ol li:before,
.editor-preview ol li:before, .editor-preview-side ul li,
.editor-preview ul li, .editor-preview-side ul li:before,
.editor-preview ul li:before, .ts-label, .dataTables_wrapper .table thead th, .panel-body label, .ts-input, .ts-small, .btn.btn-xs, .dataTables_wrapper .table tr th:not(th):not(.main-column):not(.sorting_1),
.dataTables_wrapper .table tr td:not(th):not(.main-column):not(.sorting_1), .panel-heading,
.panel-heading input, .panel-footer, .form-group .description, .advice, .form-group .slug, .editor-body, .editor-preview-side,
.editor-preview, .editor-preview-side p,
.editor-preview p, .editor-small, textarea, body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif; }

/* line 50, resources/assets/admin/css/modules/_variables.scss */
.editor-preview-side em,
.editor-preview-side i,
.editor-preview em,
.editor-preview i, .editor-preview-side blockquote > p,
.editor-preview blockquote > p, .editor-preview-side blockquote > blockquote p,
.editor-preview blockquote > blockquote p {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-style: italic; }

/* line 55, resources/assets/admin/css/modules/_variables.scss */
.ts-title, .modal-dialog .modal-title, .table-responsive .panel-heading .dataTables_filter input, .edit__title, .ts-subtitle, .panel-heading .tab-select, .panel-heading h2, .editor-title, .editor-preview-side h1,
.editor-preview-side h2,
.editor-preview h1,
.editor-preview h2, .editor-subtitle, .editor-preview-side h3,
.editor-preview h3, .editor-subsubtitle, .editor-preview-side h4,
.editor-preview-side h5,
.editor-preview-side h6,
.editor-preview h4,
.editor-preview h5,
.editor-preview h6, .editor-preview-side b,
.editor-preview-side strong,
.editor-preview b,
.editor-preview strong, .editor-preview-side dl dt,
.editor-preview dl dt, .editor-preview-side table th,
.editor-preview table th {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-weight: 700; }

/* line 60, resources/assets/admin/css/modules/_variables.scss */
.editor-preview-side em > b,
.editor-preview-side b > em,
.editor-preview-side i > b,
.editor-preview-side b > i,
.editor-preview-side em > strong,
.editor-preview-side strong > em,
.editor-preview-side i > strong,
.editor-preview-side strong > i,
.editor-preview em > b,
.editor-preview b > em,
.editor-preview i > b,
.editor-preview b > i,
.editor-preview em > strong,
.editor-preview strong > em,
.editor-preview i > strong,
.editor-preview strong > i {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-style: italic;
  font-weight: 700; }

/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/
/* line 87, resources/assets/admin/css/modules/_variables.scss */
html {
  font-size: 16px; }
  @media (max-width: 767px) {
    /* line 87, resources/assets/admin/css/modules/_variables.scss */
    html {
      font-size: 14px; } }

/* line 94, resources/assets/admin/css/modules/_variables.scss */
.ts-label, .dataTables_wrapper .table thead th, .panel-body label {
  font-size: 0.666rem;
  line-height: 1.5;
  letter-spacing: 0.06em; }

/* line 99, resources/assets/admin/css/modules/_variables.scss */
.ts-small, .btn.btn-xs, .dataTables_wrapper .table tr th:not(th):not(.main-column):not(.sorting_1),
.dataTables_wrapper .table tr td:not(th):not(.main-column):not(.sorting_1), .panel-heading,
.panel-heading input, .panel-footer, .form-group .description, .advice, .form-group .slug, .editor-small, .editor-preview-side small,
.editor-preview small, .editor-preview-side code,
.editor-preview-side samp,
.editor-preview-side pre,
.editor-preview code,
.editor-preview samp,
.editor-preview pre, .media-list .media-list__item, .tab-select, .dataTables_wrapper .dataTables_info, label {
  font-size: 0.813rem;
  line-height: 1.5; }

/* line 103, resources/assets/admin/css/modules/_variables.scss */
.ts-subtitle, .panel-heading .tab-select, .panel-heading h2, .ts-body, .editor-preview-side dl,
.editor-preview dl, .editor-preview-side ol li,
.editor-preview ol li, .editor-preview-side ol li:before,
.editor-preview ol li:before, .editor-preview-side ul li,
.editor-preview ul li, .editor-preview-side ul li:before,
.editor-preview ul li:before, .editor-body, .editor-preview-side,
.editor-preview, .editor-preview-side p,
.editor-preview p, .navbar a, .modal {
  font-size: 1rem;
  line-height: 1.5; }

/* line 107, resources/assets/admin/css/modules/_variables.scss */
.ts-input, .editor-subtitle, .editor-preview-side h3,
.editor-preview h3, .editor-subsubtitle, .editor-preview-side h4,
.editor-preview-side h5,
.editor-preview-side h6,
.editor-preview h4,
.editor-preview h5,
.editor-preview h6, .media-list .media__preview span {
  font-size: 1.125rem;
  line-height: 1.3; }

/* line 111, resources/assets/admin/css/modules/_variables.scss */
.editor-title, .editor-preview-side h1,
.editor-preview-side h2,
.editor-preview h1,
.editor-preview h2 {
  font-size: 2rem;
  line-height: 1.3; }

/* line 115, resources/assets/admin/css/modules/_variables.scss */
.ts-title, .modal-dialog .modal-title, .table-responsive .panel-heading .dataTables_filter input, .edit__title {
  font-size: 2.5rem;
  line-height: 1.3; }

/*------------------------------------*\
  #SPACING
\*------------------------------------*/
/*------------------------------------*\
  #BORDERS WIDTH
\*------------------------------------*/
/*------------------------------------*\
  #MIXINS
\*------------------------------------*/
/*------------------------------------*\
  #TEXT-STYLES
\*------------------------------------*/
/* line 14, resources/assets/admin/css/modules/_typography.scss */
.ts-body, .editor-preview-side dl,
.editor-preview dl, .editor-preview-side ol li,
.editor-preview ol li, .editor-preview-side ol li:before,
.editor-preview ol li:before, .editor-preview-side ul li,
.editor-preview ul li, .editor-preview-side ul li:before,
.editor-preview ul li:before {
  letter-spacing: .02em; }

/* line 19, resources/assets/admin/css/modules/_typography.scss */
.ts-label, .dataTables_wrapper .table thead th, .panel-body label {
  text-transform: uppercase;
  letter-spacing: .05em; }

/* line 25, resources/assets/admin/css/modules/_typography.scss */
.ts-input {
  letter-spacing: .02em; }

/* line 30, resources/assets/admin/css/modules/_typography.scss */
.ts-small, .btn.btn-xs, .dataTables_wrapper .table tr th:not(th):not(.main-column):not(.sorting_1),
.dataTables_wrapper .table tr td:not(th):not(.main-column):not(.sorting_1), .panel-heading,
.panel-heading input, .panel-footer, .form-group .description, .advice, .form-group .slug {
  text-transform: none;
  letter-spacing: .02em; }

/* line 37, resources/assets/admin/css/modules/_typography.scss */
.ts-link, .tab-select li a {
  color: #888888;
  text-decoration: none; }
  /* line 40, resources/assets/admin/css/modules/_typography.scss */
  .ts-link:hover, .tab-select li a:hover {
    color: #0065ff;
    border-color: #0065ff;
    text-decoration: none; }

/*------------------------------------*\
  #EDITOR
\*------------------------------------*/
/*------------------------------------*\
  #TITLE
\*------------------------------------*/
/* line 89, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side h2,
.editor-preview-side h3,
.editor-preview-side h4,
.editor-preview h2,
.editor-preview h3,
.editor-preview h4 {
  margin-bottom: 1em; }

/* line 94, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side h2 + h3,
.editor-preview-side h3 + h4,
.editor-preview h2 + h3,
.editor-preview h3 + h4 {
  margin-top: 0; }

/*------------------------------------*\
  #PARAGRAPH
\*------------------------------------*/
/* line 109, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side p,
.editor-preview p {
  margin-bottom: .5em; }

/*------------------------------------*\
  #LINK
\*------------------------------------*/
/* line 121, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side a,
.editor-preview a {
  color: #0066FF;
  text-decoration: none; }
  /* line 125, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side a:hover,
  .editor-preview a:hover {
    color: #888888;
    text-decoration: underline; }

/*------------------------------------*\
  #BOLD-ITALIC
\*------------------------------------*/
/*------------------------------------*\
  #BLOCKQUOTE
\*------------------------------------*/
/* line 165, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side blockquote,
.editor-preview blockquote {
  padding-left: 1em;
  border-left: 1px solid #000000;
  margin-top: 1em; }

/*------------------------------------*\
  #DEFINITIONS
\*------------------------------------*/
/* line 187, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side dl dt,
.editor-preview dl dt {
  padding-bottom: .3em; }
  /* line 190, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side dl dt:first-of-type,
  .editor-preview dl dt:first-of-type {
    padding-top: .6em;
    border-top: 1px solid #000000; }

/* line 195, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side dl dd,
.editor-preview dl dd {
  padding-bottom: .6em;
  margin-bottom: .6em;
  border-bottom: 1px #000000 solid; }

/*------------------------------------*\
  #LISTS
\*------------------------------------*/
/* line 209, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side ol,
.editor-preview ol {
  counter-reset: li; }
  /* line 212, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side ol li,
  .editor-preview ol li {
    position: relative;
    padding-left: 1.2em;
    margin-bottom: .5em; }
    /* line 217, resources/assets/admin/css/modules/_typography.scss */
    .editor-preview-side ol li:before,
    .editor-preview ol li:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: 0;
      color: rgba(0, 0, 0, 0.5); }
  /* line 226, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side ol ol > li:first-of-type,
  .editor-preview ol ol > li:first-of-type {
    margin-top: .5em; }

/* line 234, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side ul li,
.editor-preview ul li {
  position: relative;
  padding-left: 1.2em;
  margin-bottom: .5em; }
  /* line 239, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side ul li:before,
  .editor-preview ul li:before {
    content: "-";
    position: absolute;
    left: 0;
    font-size: 1em; }

/* line 247, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side ul ol > li:first-of-type,
.editor-preview ul ol > li:first-of-type {
  margin-top: .5em; }

/*------------------------------------*\
  #TABLE
\*------------------------------------*/
/* line 259, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side table,
.editor-preview table {
  width: 100%; }
  /* line 262, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side table thead,
  .editor-preview table thead {
    border-bottom: 3px solid #000000; }
  /* line 265, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side table th,
  .editor-preview table th {
    text-align: left; }
  /* line 270, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side table tbody tr:nth-child(odd),
  .editor-preview table tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.1); }
  /* line 274, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side table tfoot,
  .editor-preview table tfoot {
    border-top: 3px solid #000000; }
  /* line 277, resources/assets/admin/css/modules/_typography.scss */
  .editor-preview-side table th,
  .editor-preview-side table td,
  .editor-preview table th,
  .editor-preview table td {
    padding: .5em 0; }
  @media (max-width: 767px) {
    /* line 282, resources/assets/admin/css/modules/_typography.scss */
    .editor-preview-side table th,
    .editor-preview-side table td,
    .editor-preview table th,
    .editor-preview table td {
      display: block; } }

/*------------------------------------*\
  #SPACING
\*------------------------------------*/
/* line 297, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side p + h1,
.editor-preview-side ul + h1,
.editor-preview-side ol + h1,
.editor-preview-side blockquote + h1,
.editor-preview-side figure + h1,
.editor-preview-side p + h2,
.editor-preview-side ul + h2,
.editor-preview-side ol + h2,
.editor-preview-side blockquote + h2,
.editor-preview-side figure + h2,
.editor-preview-side p + h3,
.editor-preview-side ul + h3,
.editor-preview-side ol + h3,
.editor-preview-side blockquote + h3,
.editor-preview-side figure + h3,
.editor-preview-side p + h4,
.editor-preview-side ul + h4,
.editor-preview-side ol + h4,
.editor-preview-side blockquote + h4,
.editor-preview-side figure + h4,
.editor-preview-side p + h5,
.editor-preview-side ul + h5,
.editor-preview-side ol + h5,
.editor-preview-side blockquote + h5,
.editor-preview-side figure + h5,
.editor-preview-side p + h6,
.editor-preview-side ul + h6,
.editor-preview-side ol + h6,
.editor-preview-side blockquote + h6,
.editor-preview-side figure + h6,
.editor-preview p + h1,
.editor-preview ul + h1,
.editor-preview ol + h1,
.editor-preview blockquote + h1,
.editor-preview figure + h1,
.editor-preview p + h2,
.editor-preview ul + h2,
.editor-preview ol + h2,
.editor-preview blockquote + h2,
.editor-preview figure + h2,
.editor-preview p + h3,
.editor-preview ul + h3,
.editor-preview ol + h3,
.editor-preview blockquote + h3,
.editor-preview figure + h3,
.editor-preview p + h4,
.editor-preview ul + h4,
.editor-preview ol + h4,
.editor-preview blockquote + h4,
.editor-preview figure + h4,
.editor-preview p + h5,
.editor-preview ul + h5,
.editor-preview ol + h5,
.editor-preview blockquote + h5,
.editor-preview figure + h5,
.editor-preview p + h6,
.editor-preview ul + h6,
.editor-preview ol + h6,
.editor-preview blockquote + h6,
.editor-preview figure + h6 {
  margin-top: .6em; }

/* line 329, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side ul,
.editor-preview-side ol,
.editor-preview-side .embed-container,
.editor-preview ul,
.editor-preview ol,
.editor-preview .embed-container {
  margin-bottom: 1em; }

/*------------------------------------*\
  #MISC
\*------------------------------------*/
/* line 344, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side ol,
.editor-preview-side ul,
.editor-preview ol,
.editor-preview ul {
  list-style: none; }

/* line 348, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side sub,
.editor-preview-side sup,
.editor-preview sub,
.editor-preview sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 355, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side sup,
.editor-preview sup {
  top: -0.5em; }

/* line 358, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side sub,
.editor-preview sub {
  bottom: -0.25em; }

/* line 364, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side abbr,
.editor-preview abbr {
  border-bottom: 1px dotted #000000;
  cursor: help; }

/* line 370, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side q:before,
.editor-preview-side cite:before,
.editor-preview q:before,
.editor-preview cite:before {
  content: '"'; }

/* line 373, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side q:after,
.editor-preview-side cite:after,
.editor-preview q:after,
.editor-preview cite:after {
  content: '"'; }

/* line 377, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side mark,
.editor-preview mark {
  background-color: rgba(0, 0, 0, 0.6);
  color: #000000; }

/* line 381, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side code,
.editor-preview-side samp,
.editor-preview-side pre,
.editor-preview code,
.editor-preview samp,
.editor-preview pre {
  font-family: monospace; }

/* line 387, resources/assets/admin/css/modules/_typography.scss */
.editor-preview-side hr,
.editor-preview hr {
  background: #000000;
  height: 3px;
  border: none;
  margin: 0; }

/*------------------------------------*\
  #MISC
\*------------------------------------*/
/* line 6, resources/assets/admin/css/modules/_misc.scss */
.hidden {
  display: none !important; }

@media (max-width: 767px) {
  /* line 10, resources/assets/admin/css/modules/_misc.scss */
  .hidden-small {
    display: none !important; } }

@media (min-width: 1024px) {
  /* line 15, resources/assets/admin/css/modules/_misc.scss */
  .hidden-large {
    display: none !important; } }

/* line 21, resources/assets/admin/css/modules/_misc.scss */
.nav__list {
  list-style: none; }

/* line 29, resources/assets/admin/css/modules/_mixins.scss */
.clear::after {
  display: block;
  content: "";
  clear: both; }

/* line 30, resources/assets/admin/css/modules/_misc.scss */
.sg-main {
  padding: 2rem; }

/*------------------------------------*\
  #PARTIALS
\*------------------------------------*/
/* line 1, resources/assets/admin/css/partials/components/_navbar.scss */
.navbar__nav,
.navbar__list {
  display: flex; }

/* line 6, resources/assets/admin/css/partials/components/_navbar.scss */
.navbar {
  z-index: 2;
  background-color: #FFFFFF;
  width: 100%;
  overflow-x: auto;
  height: auto;
  border-bottom: 1px solid #efefef; }
  /* line 13, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar.navbar--fixed-top {
    position: sticky;
    top: 0; }
  /* line 17, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar a {
    color: #000000;
    text-decoration: none;
    line-height: 1;
    display: block; }
    /* line 20, resources/assets/admin/css/partials/components/_navbar.scss */
    .navbar a:hover {
      color: #0065ff;
      text-decoration: none; }
  /* line 28, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar.nav--secondary {
    margin-top: -1px;
    list-style: none;
    width: 100%;
    background: #FFFFFF;
    position: sticky;
    top: 53px;
    display: none; }
    /* line 36, resources/assets/admin/css/partials/components/_navbar.scss */
    .navbar.nav--secondary .js-dropdown-menu {
      display: flex; }
      @media (min-width: 768px) {
        /* line 36, resources/assets/admin/css/partials/components/_navbar.scss */
        .navbar.nav--secondary .js-dropdown-menu {
          justify-content: flex-end; } }
    /* line 43, resources/assets/admin/css/partials/components/_navbar.scss */
    .navbar.nav--secondary li {
      flex-shrink: 0; }
    /* line 46, resources/assets/admin/css/partials/components/_navbar.scss */
    .navbar.nav--secondary a {
      color: #888888;
      padding-left: calc(30px / 3);
      padding-right: calc(30px / 3);
      padding-top: 1.111rem;
      padding-bottom: 1.111rem; }
      /* line 52, resources/assets/admin/css/partials/components/_navbar.scss */
      .navbar.nav--secondary a:hover, .navbar.nav--secondary a.active {
        color: #0065ff; }

/* line 60, resources/assets/admin/css/partials/components/_navbar.scss */
.navbar__brand {
  position: absolute;
  left: calc(-30px - calc(30px * 0.67));
  width: 30px;
  height: 30px;
  display: block;
  top: 0.556rem;
  background-image: url("/assets/admin/images/fantom.gif");
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0; }
  /* line 71, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar__brand:hover {
    background-image: url("/assets/admin/images/fantom_.gif"); }
  @media (max-width: 767px) {
    /* line 60, resources/assets/admin/css/partials/components/_navbar.scss */
    .navbar__brand {
      margin-top: 0.556rem;
      margin-right: calc(30px / 3);
      position: static; } }

/* line 81, resources/assets/admin/css/partials/components/_navbar.scss */
.navbar__nav {
  position: relative;
  justify-content: space-between; }

/* line 87, resources/assets/admin/css/partials/components/_navbar.scss */
.navbar__list {
  align-items: center; }
  /* line 89, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar__list.navbar__list--left {
    padding-right: 1.111rem;
    flex: 1; }
    /* line 93, resources/assets/admin/css/partials/components/_navbar.scss */
    .navbar__list.navbar__list--left li:first-child a {
      padding-left: 0; }
  /* line 99, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar__list, .navbar__list li {
    flex-shrink: 0;
    list-style: none; }
  /* line 103, resources/assets/admin/css/partials/components/_navbar.scss */
  .navbar__list a {
    padding-top: 1.111rem;
    padding-bottom: 1.111rem;
    padding-left: calc(30px / 3);
    padding-right: calc(30px / 3); }

/* line 111, resources/assets/admin/css/partials/components/_navbar.scss */
.lang__link {
  text-transform: capitalize; }

/* line 2, resources/assets/admin/css/partials/components/_dropdown-menu.scss */
.js-dropdown {
  position: relative; }

/* line 6, resources/assets/admin/css/partials/components/_dropdown-menu.scss */
.js-dropdown-content,
.js-dropdown-menu {
  display: none; }

/* line 13, resources/assets/admin/css/partials/components/_dropdown-menu.scss */
.js-dropdown-toggle.js-dropdown-active .js-dropdown-icon {
  transform: rotate(180deg); }

/* line 19, resources/assets/admin/css/partials/components/_dropdown-menu.scss */
.select2-results__group {
  font-weight: bold; }

/* line 1, resources/assets/admin/css/partials/components/_article-aside.scss */
.article-aside {
  vertical-align: top; }
  @media (min-width: 768px) {
    /* line 1, resources/assets/admin/css/partials/components/_article-aside.scss */
    .article-aside {
      width: calc(33% - 30px);
      display: inline-block;
      margin-left: 30px; } }
  @media (max-width: 767px) {
    /* line 1, resources/assets/admin/css/partials/components/_article-aside.scss */
    .article-aside {
      margin: 1.111rem 0; } }
  /* line 11, resources/assets/admin/css/partials/components/_article-aside.scss */
  .article-aside .panel:not(:first-child) {
    margin-top: 1.667rem; }
  /* line 14, resources/assets/admin/css/partials/components/_article-aside.scss */
  .article-aside .panel-action {
    padding-top: 0; }

/* line 18, resources/assets/admin/css/partials/components/_article-aside.scss */
.input-media-upload {
  display: none; }

/* line 24, resources/assets/admin/css/partials/components/_article-aside.scss */
.media-panel .btn.media-add {
  position: absolute;
  top: 0;
  right: 0; }

/* line 30, resources/assets/admin/css/partials/components/_article-aside.scss */
.media-panel .message {
  position: absolute;
  top: 1.111rem;
  left: 50%; }

/* line 38, resources/assets/admin/css/partials/components/_article-aside.scss */
.media-list {
  list-style: none; }
  /* line 40, resources/assets/admin/css/partials/components/_article-aside.scss */
  .media-list .media-list__item {
    display: flex;
    border-bottom: 1px solid #efefef;
    justify-content: space-between; }
    /* line 41, resources/assets/admin/css/partials/components/_article-aside.scss */
    .media-list .media-list__item:last-child {
      border-bottom: none; }
  /* line 49, resources/assets/admin/css/partials/components/_article-aside.scss */
  .media-list .media__infos {
    width: calc(100% - 100px);
    padding: 1.111rem 0;
    justify-content: space-between;
    flex-direction: column; }
  /* line 55, resources/assets/admin/css/partials/components/_article-aside.scss */
  .media-list .media__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  /* line 60, resources/assets/admin/css/partials/components/_article-aside.scss */
  .media-list .media__actions {
    color: #888888; }
    /* line 62, resources/assets/admin/css/partials/components/_article-aside.scss */
    .media-list .media__actions form {
      display: inline; }
  /* line 66, resources/assets/admin/css/partials/components/_article-aside.scss */
  .media-list .media__preview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    display: flex; }
    /* line 72, resources/assets/admin/css/partials/components/_article-aside.scss */
    .media-list .media__preview.txt {
      background-color: #ffffe0; }
    /* line 75, resources/assets/admin/css/partials/components/_article-aside.scss */
    .media-list .media__preview span {
      margin: auto;
      text-transform: uppercase;
      color: #888888; }

/* line 2, resources/assets/admin/css/partials/components/_btn.scss */
.btn {
  width: 100%;
  display: block;
  border: none;
  background-image: none;
  background-color: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-weight: normal;
  text-decoration: none;
  text-align: left;
  text-decoration: none;
  padding: calc(1.111rem * 0.67) calc(30px / 2); }
  /* line 16, resources/assets/admin/css/partials/components/_btn.scss */
  .btn::-moz-focus-inner {
    border: 0;
    padding: 0; }
  /* line 20, resources/assets/admin/css/partials/components/_btn.scss */
  .btn.btn-primary {
    background-color: #0065ff;
    color: #FFFFFF;
    border-color: #FFFFFF; }
    /* line 24, resources/assets/admin/css/partials/components/_btn.scss */
    .btn.btn-primary:active {
      background-color: #005be6; }
  /* line 28, resources/assets/admin/css/partials/components/_btn.scss */
  .btn.btn-default {
    background-color: #0051cc;
    color: #FFFFFF;
    border-color: #0065ff; }
    /* line 32, resources/assets/admin/css/partials/components/_btn.scss */
    .btn.btn-default:active {
      background-color: #0047b3; }
  /* line 36, resources/assets/admin/css/partials/components/_btn.scss */
  .btn.btn-cancel {
    background-color: #efefef;
    color: #000000;
    border-color: #000000; }
    /* line 40, resources/assets/admin/css/partials/components/_btn.scss */
    .btn.btn-cancel:active {
      background-color: #e2e2e2; }
  /* line 44, resources/assets/admin/css/partials/components/_btn.scss */
  .btn.btn-small {
    padding: 0.556rem calc(30px / 2); }
  /* line 47, resources/assets/admin/css/partials/components/_btn.scss */
  .btn.btn-xs {
    padding: 0.357rem calc(30px / 2);
    width: auto;
    display: inline-block;
    text-align: center;
    border-radius: 20px; }
  /* line 55, resources/assets/admin/css/partials/components/_btn.scss */
  .btn.btn-disabled {
    cursor: not-allowed;
    background-color: #888888; }
    /* line 58, resources/assets/admin/css/partials/components/_btn.scss */
    .btn.btn-disabled:active {
      background-color: #888888; }

/* line 64, resources/assets/admin/css/partials/components/_btn.scss */
.btn-container {
  display: flex;
  flex-direction: row; }
  /* line 67, resources/assets/admin/css/partials/components/_btn.scss */
  .btn-container .btn {
    box-sizing: border-box;
    flex: 1; }
  /* line 71, resources/assets/admin/css/partials/components/_btn.scss */
  .btn-container .btn-primary {
    flex: 3; }

/* line 1, resources/assets/admin/css/partials/components/_links.scss */
.link, .dataTables_wrapper .table tr th.main-column a,
.dataTables_wrapper .table tr td.main-column a, .dataTables_wrapper .panel-footer .dataTables_paginate a {
  color: #888888;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid; }
  /* line 6, resources/assets/admin/css/partials/components/_links.scss */
  .link:hover, .dataTables_wrapper .table tr th.main-column a:hover,
  .dataTables_wrapper .table tr td.main-column a:hover, .dataTables_wrapper .panel-footer .dataTables_paginate a:hover {
    color: #0065ff;
    border-color: #0065ff;
    text-decoration: none; }
  /* line 12, resources/assets/admin/css/partials/components/_links.scss */
  .link.link--delete:hover, .dataTables_wrapper .table tr th.main-column a.link--delete:hover,
  .dataTables_wrapper .table tr td.main-column a.link--delete:hover, .dataTables_wrapper .panel-footer .dataTables_paginate a.link--delete:hover {
    color: #FF0000;
    border-color: #FF0000;
    text-decoration: none; }

/* line 20, resources/assets/admin/css/partials/components/_links.scss */
.link + .link, .dataTables_wrapper .table tr th.main-column a + .link, .dataTables_wrapper .table tr td.main-column a + .link, .dataTables_wrapper .panel-footer .dataTables_paginate a + .link, .dataTables_wrapper .table tr th.main-column .link + a, .dataTables_wrapper .table tr th.main-column a + a,
.dataTables_wrapper .table tr td.main-column .link + a,
.dataTables_wrapper .table tr td.main-column a + a, .dataTables_wrapper .panel-footer .dataTables_paginate .link + a, .dataTables_wrapper .table tr th.main-column .panel-footer .dataTables_paginate a + a, .dataTables_wrapper .table tr td.main-column .panel-footer .dataTables_paginate a + a, .dataTables_wrapper .panel-footer .dataTables_paginate a + a {
  margin-left: 0.556rem; }

/* line 1, resources/assets/admin/css/partials/components/_modal.scss */
.modal {
  display: none;
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #efefef;
  overflow: scroll; }
  /* line 12, resources/assets/admin/css/partials/components/_modal.scss */
  .modal .modal-body {
    padding-top: 1.667rem; }
  /* line 16, resources/assets/admin/css/partials/components/_modal.scss */
  .modal img,
  .modal video {
    max-width: 100%;
    height: auto;
    display: block; }
  /* line 22, resources/assets/admin/css/partials/components/_modal.scss */
  .modal .panel-heading {
    margin-top: 1.111rem; }
  /* line 25, resources/assets/admin/css/partials/components/_modal.scss */
  .modal .panel-action {
    margin-bottom: 2.222rem; }

/* line 31, resources/assets/admin/css/partials/components/_modal.scss */
.modal-dialog .modal-content {
  width: 100%;
  height: 100vh;
  display: flex; }

/* line 36, resources/assets/admin/css/partials/components/_modal.scss */
.modal-dialog .modal-header {
  display: block;
  margin: auto;
  max-width: 100%;
  width: 350px; }

/* line 42, resources/assets/admin/css/partials/components/_modal.scss */
.modal-dialog .modal-title {
  color: #000000;
  margin-bottom: 1.667rem; }

/* line 1, resources/assets/admin/css/partials/components/_tabs.scss */
.tab-pane {
  display: none; }
  /* line 3, resources/assets/admin/css/partials/components/_tabs.scss */
  .tab-pane.active {
    display: block; }

/* line 8, resources/assets/admin/css/partials/components/_tabs.scss */
.tab-select {
  list-style: none;
  margin-bottom: -1px; }
  /* line 12, resources/assets/admin/css/partials/components/_tabs.scss */
  .tab-select li {
    display: inline-block;
    text-align: center;
    padding: 0.357rem 1.111rem;
    text-transform: capitalize;
    outline: 1px solid #FFFFFF;
    outline-offset: -1px; }
    /* line 22, resources/assets/admin/css/partials/components/_tabs.scss */
    .tab-select li.active {
      background-color: #FFFFFF; }
      /* line 24, resources/assets/admin/css/partials/components/_tabs.scss */
      .tab-select li.active a {
        color: #000000; }

/* line 1, resources/assets/admin/css/partials/components/_datatables.scss */
table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.5;
  table-layout: fixed;
  z-index: 2001;
  max-height: 40px; }
  /* line 7, resources/assets/admin/css/partials/components/_datatables.scss */
  table.dt-rowReorder-float td {
    cursor: grabbing !important; }
  /* line 10, resources/assets/admin/css/partials/components/_datatables.scss */
  table.dt-rowReorder-float .text-content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

/* line 18, resources/assets/admin/css/partials/components/_datatables.scss */
tr.dt-rowReorder-moving {
  outline: 1px solid #0065ff;
  outline-offset: -1px;
  max-height: 32px; }

/* line 24, resources/assets/admin/css/partials/components/_datatables.scss */
body.dt-rowReorder-noOverflow {
  overflow-x: hidden; }

/* line 35, resources/assets/admin/css/partials/components/_datatables.scss */
.table-reorderable > tbody > tr > td:last-child {
  position: relative;
  padding-right: 0 !important; }
  /* line 38, resources/assets/admin/css/partials/components/_datatables.scss */
  .table-reorderable > tbody > tr > td:last-child .reorder {
    padding: 0.556rem;
    cursor: grab;
    color: #888888;
    opacity: 0.3;
    transition: 0.2; }
    /* line 44, resources/assets/admin/css/partials/components/_datatables.scss */
    .table-reorderable > tbody > tr > td:last-child .reorder:hover {
      opacity: 0.8; }

/* line 51, resources/assets/admin/css/partials/components/_datatables.scss */
.datatable-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  font-size: 2.5rem;
  font-weight: bold;
  background-color: #efefef; }

/* line 1, resources/assets/admin/css/partials/components/_footer.scss */
.footer__main {
  margin-top: 4.444rem;
  border-top: 1px solid #BCBCBC;
  font-size: 0.813rem; }
  /* line 5, resources/assets/admin/css/partials/components/_footer.scss */
  .footer__main ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1.111rem; }
  /* line 11, resources/assets/admin/css/partials/components/_footer.scss */
  .footer__main li {
    list-style-type: none; }

/* line 3, resources/assets/admin/css/partials/components/_index.scss */
.table-responsive .btn {
  position: absolute;
  right: 0;
  top: 0.8rem; }

/* line 8, resources/assets/admin/css/partials/components/_index.scss */
.table-responsive .panel-body:not(.panel-body--medias) {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0; }

/* line 15, resources/assets/admin/css/partials/components/_index.scss */
.table-responsive .panel-heading {
  padding-top: 0;
  margin-bottom: 0.556rem; }
  /* line 19, resources/assets/admin/css/partials/components/_index.scss */
  .table-responsive .panel-heading .dataTables_filter label {
    width: 80%; }
    @media (max-width: 767px) {
      /* line 19, resources/assets/admin/css/partials/components/_index.scss */
      .table-responsive .panel-heading .dataTables_filter label {
        width: calc(100% - 100px); } }
  /* line 25, resources/assets/admin/css/partials/components/_index.scss */
  .table-responsive .panel-heading .dataTables_filter input {
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
    cursor: pointer; }
    /* line 32, resources/assets/admin/css/partials/components/_index.scss */
    .table-responsive .panel-heading .dataTables_filter input::placeholder {
      /* Chrome/Opera/Safari */
      color: #000000 !important;
      opacity: 1 !important; }
    /* line 36, resources/assets/admin/css/partials/components/_index.scss */
    .table-responsive .panel-heading .dataTables_filter input:hover::placeholder {
      opacity: 0.5 !important; }
    /* line 39, resources/assets/admin/css/partials/components/_index.scss */
    .table-responsive .panel-heading .dataTables_filter input:focus {
      cursor: text;
      outline: none; }

/* line 51, resources/assets/admin/css/partials/components/_index.scss */
.dataTables_wrapper .table {
  border: none; }
  /* line 54, resources/assets/admin/css/partials/components/_index.scss */
  .dataTables_wrapper .table thead th {
    padding-bottom: 0.357rem !important; }
  /* line 59, resources/assets/admin/css/partials/components/_index.scss */
  .dataTables_wrapper .table tr {
    position: relative; }
    /* line 60, resources/assets/admin/css/partials/components/_index.scss */
    .dataTables_wrapper .table tr:not(:last-of-type) {
      border-bottom: 1px solid #efefef; }
    /* line 64, resources/assets/admin/css/partials/components/_index.scss */
    .dataTables_wrapper .table tr th,
    .dataTables_wrapper .table tr td {
      padding: 0 5px 0 calc(30px / 2);
      max-width: 20vw; }
      /* line 68, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .table tr th.main-column,
      .dataTables_wrapper .table tr td.main-column {
        width: 60%;
        max-width: 50vw;
        padding-top: 0.834rem;
        padding-bottom: 0.834rem; }
        /* line 73, resources/assets/admin/css/partials/components/_index.scss */
        .dataTables_wrapper .table tr th.main-column a,
        .dataTables_wrapper .table tr td.main-column a {
          border-bottom: none;
          color: #000000; }
      /* line 79, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .table tr th:not(th):not(.main-column):not(.sorting_1),
      .dataTables_wrapper .table tr td:not(th):not(.main-column):not(.sorting_1) {
        color: #888888; }
      /* line 83, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .table tr th .text-content,
      .dataTables_wrapper .table tr td .text-content {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      /* line 90, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .table tr th.is-published,
      .dataTables_wrapper .table tr td.is-published {
        width: 0;
        text-align: center; }
        /* line 93, resources/assets/admin/css/partials/components/_index.scss */
        .dataTables_wrapper .table tr th.is-published + td,
        .dataTables_wrapper .table tr th.is-published + th,
        .dataTables_wrapper .table tr td.is-published + td,
        .dataTables_wrapper .table tr td.is-published + th {
          padding-left: 0; }
      /* line 98, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .table tr th:first-child,
      .dataTables_wrapper .table tr td:first-child {
        padding-left: calc(30px / 2); }
        /* line 100, resources/assets/admin/css/partials/components/_index.scss */
        .dataTables_wrapper .table tr th:first-child i,
        .dataTables_wrapper .table tr td:first-child i {
          margin-bottom: 7px; }
      /* line 104, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .table tr th:last-child,
      .dataTables_wrapper .table tr td:last-child {
        text-align: right;
        padding-right: calc(30px / 2); }

/* line 112, resources/assets/admin/css/partials/components/_index.scss */
.dataTables_wrapper .panel-body .highlight {
  background-color: #ffffe0; }

/* line 116, resources/assets/admin/css/partials/components/_index.scss */
.dataTables_wrapper .panel-footer {
  list-style: none;
  display: flex;
  justify-content: space-between; }
  /* line 121, resources/assets/admin/css/partials/components/_index.scss */
  .dataTables_wrapper .panel-footer .dataTables_paginate a {
    margin-right: 0.357rem;
    border: none; }
  @media (max-width: 767px) {
    /* line 116, resources/assets/admin/css/partials/components/_index.scss */
    .dataTables_wrapper .panel-footer {
      flex-direction: column-reverse; }
      /* line 132, resources/assets/admin/css/partials/components/_index.scss */
      .dataTables_wrapper .panel-footer .dataTables_paginate {
        font-size: 1.125rem;
        text-align: center;
        margin-bottom: 1.111rem; } }

/* line 142, resources/assets/admin/css/partials/components/_index.scss */
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_info {
  display: inline-block; }

/* line 146, resources/assets/admin/css/partials/components/_index.scss */
.dataTables_wrapper .dataTables_length {
  margin-right: 0.556rem; }
  /* line 148, resources/assets/admin/css/partials/components/_index.scss */
  .dataTables_wrapper .dataTables_length:after {
    content: "/";
    margin-left: 0.357rem; }

/* line 1, resources/assets/admin/css/partials/components/_panels.scss */
.panel {
  position: relative; }

/* line 5, resources/assets/admin/css/partials/components/_panels.scss */
.panel-heading {
  padding-top: 0.556rem;
  padding-bottom: 0.834rem; }
  /* line 16, resources/assets/admin/css/partials/components/_panels.scss */
  .panel-heading h2,
  .panel-heading h3,
  .panel-heading h4,
  .panel-heading h5,
  .panel-heading h6 {
    display: inline; }

/* line 27, resources/assets/admin/css/partials/components/_panels.scss */
.panel-body {
  padding-left: calc(30px / 2);
  padding-right: calc(30px / 2);
  padding-top: 1.111rem;
  padding-bottom: 1.111rem;
  background-color: #FFFFFF; }
  /* line 28, resources/assets/admin/css/partials/components/_panels.scss */
  .panel-body label {
    color: #000000; }
  /* line 37, resources/assets/admin/css/partials/components/_panels.scss */
  .panel-body--medias {
    padding-left: 0; }

/* line 42, resources/assets/admin/css/partials/components/_panels.scss */
.panel-footer {
  list-style: none;
  margin-top: 1.111rem;
  margin-bottom: 1.667rem;
  color: #888888; }
  /* line 44, resources/assets/admin/css/partials/components/_panels.scss */
  .panel-footer li {
    margin-right: 0;
    display: inline-block; }
    /* line 47, resources/assets/admin/css/partials/components/_panels.scss */
    .panel-footer li:not(:first-child)::before {
      content: '/';
      margin-right: 0.357rem; }

/* line 58, resources/assets/admin/css/partials/components/_panels.scss */
.panel-action {
  position: sticky;
  bottom: 0;
  border-top: 3px solid #efefef;
  margin-top: 1.111rem; }

/* line 2, resources/assets/admin/css/partials/components/_forms.scss */
.CodeMirror,
.editor-toolbar,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default .select2-selection--multiple,
.select2-selection,
.select2-dropdown,
.form-control {
  border-width: 1px !important;
  border-color: #BCBCBC !important;
  border-style: solid;
  border-radius: 0px !important; }

/* line 15, resources/assets/admin/css/partials/components/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  resize: none;
  padding: 0.556rem 0.556rem; }
  /* line 20, resources/assets/admin/css/partials/components/_forms.scss */
  .form-control:focus {
    outline: none; }
  /* line 23, resources/assets/admin/css/partials/components/_forms.scss */
  .form-control.colorpicker {
    width: auto;
    padding: 0 0; }

/* line 30, resources/assets/admin/css/partials/components/_forms.scss */
.select2-container {
  line-height: 1; }
  /* line 32, resources/assets/admin/css/partials/components/_forms.scss */
  .select2-container .select2-selection--single {
    height: auto; }
    /* line 34, resources/assets/admin/css/partials/components/_forms.scss */
    .select2-container .select2-selection--single .select2-selection__arrow {
      height: 20px;
      top: 50%;
      transform: translateY(-50%); }
    /* line 39, resources/assets/admin/css/partials/components/_forms.scss */
    .select2-container .select2-selection--single .select2-selection__clear {
      left: -20px; }
    /* line 42, resources/assets/admin/css/partials/components/_forms.scss */
    .select2-container .select2-selection--single .select2-results__option--highlighted[aria-selected] {
      background: #0065ff; }
  /* line 46, resources/assets/admin/css/partials/components/_forms.scss */
  .select2-container .select2-selection__rendered {
    padding: 0.357rem !important; }
    /* line 50, resources/assets/admin/css/partials/components/_forms.scss */
    .select2-container .select2-selection__rendered .select2-selection__choice {
      border: none;
      border-radius: 0;
      background-color: #efefef;
      margin: 0;
      padding: 0.357rem;
      margin-right: 0.556rem; }
      /* line 57, resources/assets/admin/css/partials/components/_forms.scss */
      .select2-container .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
        margin-right: 0.357rem; }

/* line 64, resources/assets/admin/css/partials/components/_forms.scss */
.select2-dropdown .select2-results__option[aria-selected="true"] {
  background: #efefef; }

/* line 67, resources/assets/admin/css/partials/components/_forms.scss */
.select2-dropdown .select2-results__option--highlighted[aria-selected] {
  background: #888888; }

/* line 73, resources/assets/admin/css/partials/components/_forms.scss */
.editor-toolbar::after {
  margin-top: 0.357rem; }

/* line 74, resources/assets/admin/css/partials/components/_forms.scss */
.editor-toolbar::before {
  margin-bottom: 0.357rem; }

/* line 75, resources/assets/admin/css/partials/components/_forms.scss */
.editor-toolbar, .editor-toolbar:hover {
  opacity: 1; }

/* line 78, resources/assets/admin/css/partials/components/_forms.scss */
.editor-toolbar a {
  color: #888888 !important;
  border: none !important; }
  /* line 81, resources/assets/admin/css/partials/components/_forms.scss */
  .editor-toolbar a:hover {
    color: #0065ff !important; }

/* line 86, resources/assets/admin/css/partials/components/_forms.scss */
.cm-s-paper {
  border-top: none; }

/* line 89, resources/assets/admin/css/partials/components/_forms.scss */
.CodeMirror {
  z-index: 0; }

/* line 92, resources/assets/admin/css/partials/components/_forms.scss */
.CodeMirror-fullscreen {
  z-index: 10; }

/* line 95, resources/assets/admin/css/partials/components/_forms.scss */
label {
  display: inline-block; }

/* line 100, resources/assets/admin/css/partials/components/_forms.scss */
.form-group {
  margin-top: 1.111rem; }
  /* line 102, resources/assets/admin/css/partials/components/_forms.scss */
  .form-group:first-child {
    margin-top: 0; }
  /* line 105, resources/assets/admin/css/partials/components/_forms.scss */
  .form-group label {
    margin-bottom: 0.357rem; }
  /* line 108, resources/assets/admin/css/partials/components/_forms.scss */
  .form-group .description {
    display: block;
    color: #888888; }
  /* line 113, resources/assets/admin/css/partials/components/_forms.scss */
  .form-group.form-group--container {
    margin-top: 0; }
    @media (min-width: 768px) {
      /* line 113, resources/assets/admin/css/partials/components/_forms.scss */
      .form-group.form-group--container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; } }
    /* line 120, resources/assets/admin/css/partials/components/_forms.scss */
    .form-group.form-group--container .form-group--small {
      margin-top: 1.111rem;
      width: calc(50% - (30px / 4));
      display: inline-block; }
      @media (max-width: 767px) {
        /* line 120, resources/assets/admin/css/partials/components/_forms.scss */
        .form-group.form-group--container .form-group--small {
          width: 100%;
          display: block; } }

/* line 132, resources/assets/admin/css/partials/components/_forms.scss */
input[type="checkbox"] {
  margin-right: 0.357rem; }

/* line 138, resources/assets/admin/css/partials/components/_forms.scss */
input::placeholder,
textarea::placeholder,
.CodeMirror .CodeMirror-placeholder,
.select2-container .select2-selection .select2-selection__placeholder,
.select2-selection__placeholder {
  color: #888888 !important;
  opacity: 0.7 !important; }

/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/
/* line 5, resources/assets/admin/css/partials/templates/_global.scss */
.main-content {
  padding-top: 1.667rem !important;
  min-height: calc(100vh - (53px * 2) - 4.444rem); }
  /* line 8, resources/assets/admin/css/partials/templates/_global.scss */
  .tools .main-content {
    min-height: calc(100vh - (53px * 3) - 4.444rem); }

/* line 13, resources/assets/admin/css/partials/templates/_global.scss */
.wrapper, .navbar.nav--secondary .js-dropdown-menu, .navbar__nav, .modal .modal-body {
  margin: 0 auto;
  width: 90%;
  max-width: 1600px; }
  /* line 17, resources/assets/admin/css/partials/templates/_global.scss */
  .wrapper.wrapper--small, .navbar.nav--secondary .wrapper--small.js-dropdown-menu, .wrapper--small.navbar__nav, .modal .wrapper--small.modal-body {
    width: 60%;
    max-width: 1000px; }

/* line 23, resources/assets/admin/css/partials/templates/_global.scss */
a {
  cursor: pointer;
  text-decoration: none; }

/* line 28, resources/assets/admin/css/partials/templates/_global.scss */
.icon-published, .edit-details .is-published span::before {
  font-size: 0.666rem;
  vertical-align: baseline;
  margin-right: 0.357rem;
  color: #0065ff; }

/* line 35, resources/assets/admin/css/partials/templates/_global.scss */
.help-block,
.alert {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  text-align: left;
  background-color: #ffffe0;
  overflow: hidden;
  z-index: 999;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  /* line 50, resources/assets/admin/css/partials/templates/_global.scss */
  .help-block:before,
  .alert:before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0.556rem;
    left: calc(5% - 50px);
    display: block;
    background-image: url("/assets/admin/images/fantom_.gif");
    background-repeat: no-repeat;
    background-position: center; }

/* line 64, resources/assets/admin/css/partials/templates/_global.scss */
.advice {
  color: #888888; }

/* line 69, resources/assets/admin/css/partials/templates/_global.scss */
.fantom {
  background-image: url("/assets/admin/images/fantom.gif");
  background-repeat: no-repeat;
  background-size: contain;
  height: 22px;
  width: 28px;
  position: absolute;
  z-index: -1; }

/* line 1, resources/assets/admin/css/partials/templates/_login.scss */
.login .main-content,
.reset_mail .main-content,
.reset_password .main-content {
  width: 50%;
  max-width: 1000px;
  min-height: calc(100vh - 1.111rem); }
  @media (max-width: 767px) {
    /* line 1, resources/assets/admin/css/partials/templates/_login.scss */
    .login .main-content,
    .reset_mail .main-content,
    .reset_password .main-content {
      width: 80%; } }

/* line 13, resources/assets/admin/css/partials/templates/_login.scss */
.login .panel, .reset_mail .panel, .reset_password .panel {
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media (max-width: 767px) {
    /* line 13, resources/assets/admin/css/partials/templates/_login.scss */
    .login .panel, .reset_mail .panel, .reset_password .panel {
      width: 80%; } }

/* line 26, resources/assets/admin/css/partials/templates/_login.scss */
.remember label {
  width: 50%;
  text-align: right; }

/* line 33, resources/assets/admin/css/partials/templates/_login.scss */
.login .form-group, .reset_mail .form-group, .reset_password .form-group {
  margin-top: 0; }
  /* line 35, resources/assets/admin/css/partials/templates/_login.scss */
  .login .form-group > input, .reset_mail .form-group > input, .reset_password .form-group > input {
    padding: 0.556rem 0.357rem;
    width: 100%;
    margin: 0 auto;
    border-bottom: none;
    border-top: 1px solid #efefef !important;
    border-right: 3px solid #0065ff !important;
    border-left: 3px solid #0065ff !important; }

/* line 1, resources/assets/admin/css/partials/templates/_article-edit.scss */
.edit__header {
  margin-bottom: 0.556rem; }

/* line 4, resources/assets/admin/css/partials/templates/_article-edit.scss */
.edit__title {
  display: block; }

@media (min-width: 768px) {
  /* line 10, resources/assets/admin/css/partials/templates/_article-edit.scss */
  .panel.panel-edit {
    width: 66%;
    display: inline-block; } }

/* line 15, resources/assets/admin/css/partials/templates/_article-edit.scss */
.panel.panel-edit.panel-edit--single {
  display: block;
  margin: 0 auto; }
  /* line 18, resources/assets/admin/css/partials/templates/_article-edit.scss */
  .panel.panel-edit.panel-edit--single .panel-heading {
    padding-bottom: 0.556rem; }

/* line 22, resources/assets/admin/css/partials/templates/_article-edit.scss */
.panel.panel-edit .panel-heading {
  padding-top: 0.556rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  /* line 28, resources/assets/admin/css/partials/templates/_article-edit.scss */
  .panel.panel-edit .panel-heading .tab-select {
    flex-shrink: 0; }
  /* line 31, resources/assets/admin/css/partials/templates/_article-edit.scss */
  .panel.panel-edit .panel-heading .edit-details {
    text-align: right; }
    /* line 33, resources/assets/admin/css/partials/templates/_article-edit.scss */
    .panel.panel-edit .panel-heading .edit-details > * {
      display: inline-block; }
      /* line 35, resources/assets/admin/css/partials/templates/_article-edit.scss */
      .panel.panel-edit .panel-heading .edit-details > *:not(:first-child) {
        margin-left: 0.556rem; }
    /* line 41, resources/assets/admin/css/partials/templates/_article-edit.scss */
    .panel.panel-edit .panel-heading .edit-details input {
      line-height: 1; }

/* line 47, resources/assets/admin/css/partials/templates/_article-edit.scss */
.panel .panel-body + .panel-body {
  margin-top: 1.111rem; }

/* line 52, resources/assets/admin/css/partials/templates/_article-edit.scss */
.created_at input {
  border: none;
  padding: 0;
  text-align: right;
  background-color: transparent;
  color: #888888; }
  /* line 58, resources/assets/admin/css/partials/templates/_article-edit.scss */
  .created_at input:focus {
    color: #000000; }

/* line 64, resources/assets/admin/css/partials/templates/_article-edit.scss */
.edit-details .is-published label {
  cursor: pointer; }

/* line 67, resources/assets/admin/css/partials/templates/_article-edit.scss */
.edit-details .is-published input[type="checkbox"] {
  display: none; }

/* line 70, resources/assets/admin/css/partials/templates/_article-edit.scss */
.edit-details .is-published span::before {
  content: "\f10c";
  font-family: 'FontAwesome'; }

/* line 75, resources/assets/admin/css/partials/templates/_article-edit.scss */
.edit-details .is-published :checked + span::before {
  content: "\f111"; }

/* line 81, resources/assets/admin/css/partials/templates/_article-edit.scss */
.form-group .slug {
  display: inline-block;
  color: #888888;
  margin-top: 0.357rem; }

/* line 2, resources/assets/admin/css/partials/templates/_settings-index.scss */
.settings-index .panel-body ~ .panel-body,
.settings-index .form-horizontal + .form-horizontal {
  margin-top: 1.111rem; }

/* line 5, resources/assets/admin/css/partials/templates/_medias-index.scss */
body.medias .table tr th.media-preview,
body.medias .table tr td.media-preview {
  width: 100px;
  padding-right: 0; }
  /* line 8, resources/assets/admin/css/partials/templates/_medias-index.scss */
  body.medias .table tr th.media-preview > div,
  body.medias .table tr th.media-preview img,
  body.medias .table tr td.media-preview > div,
  body.medias .table tr td.media-preview img {
    width: 100%;
    max-width: 100%;
    min-width: 35px;
    height: 75px;
    object-fit: cover;
    object-position: center;
    display: block; }
    @media (max-width: 767px) {
      /* line 8, resources/assets/admin/css/partials/templates/_medias-index.scss */
      body.medias .table tr th.media-preview > div,
      body.medias .table tr th.media-preview img,
      body.medias .table tr td.media-preview > div,
      body.medias .table tr td.media-preview img {
        height: 35px; } }
  /* line 21, resources/assets/admin/css/partials/templates/_medias-index.scss */
  body.medias .table tr th.media-preview > div,
  body.medias .table tr td.media-preview > div {
    background-color: #ffffe0;
    position: relative; }
    /* line 24, resources/assets/admin/css/partials/templates/_medias-index.scss */
    body.medias .table tr th.media-preview > div span,
    body.medias .table tr td.media-preview > div span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

/* line 1, resources/assets/admin/css/partials/templates/_medias-edit.scss */
.form-group.model {
  margin-bottom: 1.667rem; }

/* line 5, resources/assets/admin/css/partials/templates/_pages-index.scss */
.dataTables_wrapper .table .has-child .main-column {
  cursor: pointer; }
  /* line 7, resources/assets/admin/css/partials/templates/_pages-index.scss */
  .dataTables_wrapper .table .has-child .main-column .text-content::after {
    content: ' ';
    font: normal normal normal 14px/1 FontAwesome;
    display: inline-block;
    margin-left: 0.357rem; }

/* line 15, resources/assets/admin/css/partials/templates/_pages-index.scss */
.dataTables_wrapper .table .has-child.shown .text-content::after {
  transform: rotateX(180deg); }

/* line 21, resources/assets/admin/css/partials/templates/_pages-index.scss */
.dataTables_wrapper .table tr.datatable-child > td {
  padding: 0;
  padding-left: 50px; }

/* line 25, resources/assets/admin/css/partials/templates/_pages-index.scss */
.dataTables_wrapper .table tr.datatable-child table {
  width: 100%; }
  /* line 27, resources/assets/admin/css/partials/templates/_pages-index.scss */
  .dataTables_wrapper .table tr.datatable-child table tr {
    border-bottom: none; }

/*------------------------------------*\
  #VENDOR
\*------------------------------------*/
/*------------------------------------*\
  #BASE-STYLES
\*------------------------------------*/
/* line 73, resources/assets/admin/css/main.scss */
body {
  background-color: #efefef;
  color: #000000;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
  /* line 79, resources/assets/admin/css/main.scss */
  body ::-moz-selection {
    background-color: #ffffe0;
    color: #000000; }
  /* line 80, resources/assets/admin/css/main.scss */
  body ::selection {
    background-color: #ffffe0;
    color: #000000; }

/* ----- Loading ----- */
@keyframes blink {
  50% {
    opacity: 0.0; } }

/* line 91, resources/assets/admin/css/main.scss */
.fa.blink {
  display: none;
  animation: blink .5s step-start 0s infinite; }
  /* line 94, resources/assets/admin/css/main.scss */
  .loading .fa.blink {
    display: inline; }
