/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/

.main-content {
  padding-top: $spacing-md !important;
  min-height: calc(100vh - (53px * 2) - #{$spacing-xxl});
  .tools & {
    min-height: calc(100vh - (53px * 3) - #{$spacing-xxl});
  }
}

.wrapper {
  margin:0 auto;
  width: 90%;
  max-width: 1600px;
  &.wrapper--small {
    width: 60%;
    max-width: 1000px;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

.icon-published {
  font-size: $font-size-xs;
  vertical-align: baseline;
  margin-right: $spacing-xxs;
  color: $blue;
}

.help-block,
.alert {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  // display: block;
  text-align: left;
  background-color: $lightyellow;
  overflow: hidden;
  z-index: 999;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  &:before {
    content:"";
    width: 30px;
    height:30px;
    position:absolute;
    top: $spacing-xs;
    left:calc(5% - 50px);
    display: block;
    background-image: url('/assets/admin/images/fantom_.gif');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.advice {
  @extend .ts-small;
  color: $gray;
}

.fantom{
  background-image: url('/assets/admin/images/fantom.gif');
  background-repeat: no-repeat;
  background-size: contain;
  height:22px;
  width:28px;
  position:absolute;
  z-index:-1;
}
